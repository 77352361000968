import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { addOrderLineItems, orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { IconButton } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import { OrderLineItem } from 'pages/ordering/OrderLineItem'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import AddMissingItemDialog from './AddMissingItemDialog'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const AddMissingItemButton = ({ allLineItems, project }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openAddMissingItem, setOpenAddMissingItem] = useState(false)
  const selectedDistributor = getSupplierConfig(useSelector(orgSelectors.getSelectedHardwareSupplier))?.filterKey
  const confirmedOrderProject = useSelector(orderSelectors.getConfirmedOrderProjectIds)
  const addedCart = confirmedOrderProject.includes(project.id)

  const handleOpenAddMissingItem = () => {
    if (selectedDistributor === undefined) {
      // TODO: handle the edge case where selectedDistributor not selected
      return
    }
    setOpenAddMissingItem(true)
    const lineItems = allLineItems.map((item) => {
      return new OrderLineItem({
        code: item.code,
        quantity: item.missingItems,
        projectOrder: [project],
        selectedDistributor,
        componentType: item.component_type,
        inventoryForecastData: {
          allocatedQuantity: item.allocated,
          requiredQuantity: item.required,
          validity: item.validity,
        },
      })
    })
    dispatch(addOrderLineItems(lineItems))
    logAmplitudeEvent('generic_button_clicked', {
      source: 'add_missing_item',
      context: 'inventory_forecast_add_missing_item',
    })
  }

  const handleCloseAddMissingItem = () => {
    setOpenAddMissingItem(false)
  }

  return (
    <div className={classes.container}>
      {addedCart && <div>Added to cart</div>}
      {!addedCart && (
        <IconButton onClick={handleOpenAddMissingItem}>
          <AddShoppingCartOutlinedIcon />
        </IconButton>
      )}
      {openAddMissingItem && (
        <AddMissingItemDialog
          handleCloseAddMissingItem={handleCloseAddMissingItem}
          project={project}
          trackSource="missing_items_modal"
        />
      )}
    </div>
  )
}

export default AddMissingItemButton
