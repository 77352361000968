import { HardwareSupplierEnum, HardwareSupplierFilterKeyType } from 'pages/ordering/type'

const CITY_PLUMBING_DISTRIBUTOR_KEY = 'city_plumbing'
const OUTLET_DISTRIBUTOR_KEY = 'outlet'
const SEGEN_DISTRIBUTOR_KEY = 'segen'
const HDM_DISTRIBUTOR_KEY = 'hdm'
const VTAC_UK_DISTRIBUTOR_KEY = 'vtac_uk'
const VTAC_POLAND_DISTRIBUTOR_KEY = 'vtac_pl'

export type HardwareSupplierPresetConfig = {
  type: HardwareSupplierEnum
  name: string
  filterKey: HardwareSupplierFilterKeyType
  logoUrl: string
}

export const SegenPresetConfig: HardwareSupplierPresetConfig = {
  type: HardwareSupplierEnum.Segen,
  name: 'Segen',
  filterKey: SEGEN_DISTRIBUTOR_KEY,
  logoUrl: `${window.PUBLIC_URL}/images/segen-logo.png`,
}

export const OutletPresetConfig: HardwareSupplierPresetConfig = {
  type: HardwareSupplierEnum.SolarOutlet,
  name: 'Solar Outlet',
  filterKey: OUTLET_DISTRIBUTOR_KEY,
  logoUrl: `${window.PUBLIC_URL}/images/solar_outlet_logo.png`,
}

export const CityPlumbingPresetConfig: HardwareSupplierPresetConfig = {
  type: HardwareSupplierEnum.CityPlumbing,
  name: 'City Plumbing',
  filterKey: CITY_PLUMBING_DISTRIBUTOR_KEY,
  logoUrl: `${window.PUBLIC_URL}/images/city-plumbing-logo.png`,
}

export const HdmPresetConfig: HardwareSupplierPresetConfig = {
  type: HardwareSupplierEnum.Hdm,
  name: 'HDM',
  filterKey: HDM_DISTRIBUTOR_KEY,
  logoUrl: `${window.PUBLIC_URL}/images/hdm-logo.png`,
}

export const VtacUkPresetConfig: HardwareSupplierPresetConfig = {
  type: HardwareSupplierEnum.VtacUk,
  name: 'V-Tac',
  filterKey: VTAC_UK_DISTRIBUTOR_KEY,
  logoUrl: `${window.PUBLIC_URL}/images/vtac-logo.png`,
}

export const VtacPolandPresetConfig: HardwareSupplierPresetConfig = {
  type: HardwareSupplierEnum.VtacPoland,
  name: 'V-Tac',
  filterKey: VTAC_POLAND_DISTRIBUTOR_KEY,
  logoUrl: `${window.PUBLIC_URL}/images/vtac-logo.png`,
}

const HARDWARE_SUPPLIER_ENUM_MAPPING: { [key in HardwareSupplierFilterKeyType]: HardwareSupplierEnum } = {
  [CITY_PLUMBING_DISTRIBUTOR_KEY]: HardwareSupplierEnum.CityPlumbing,
  [OUTLET_DISTRIBUTOR_KEY]: HardwareSupplierEnum.SolarOutlet,
  [SEGEN_DISTRIBUTOR_KEY]: HardwareSupplierEnum.Segen,
  [HDM_DISTRIBUTOR_KEY]: HardwareSupplierEnum.Hdm,
  [VTAC_UK_DISTRIBUTOR_KEY]: HardwareSupplierEnum.VtacUk,
  [VTAC_POLAND_DISTRIBUTOR_KEY]: HardwareSupplierEnum.VtacPoland,
}

const HARDWARE_SUPPLIER_CONFIG_MAPPING: { [key in HardwareSupplierFilterKeyType]: HardwareSupplierPresetConfig } = {
  [CITY_PLUMBING_DISTRIBUTOR_KEY]: CityPlumbingPresetConfig,
  [OUTLET_DISTRIBUTOR_KEY]: OutletPresetConfig,
  [SEGEN_DISTRIBUTOR_KEY]: SegenPresetConfig,
  [HDM_DISTRIBUTOR_KEY]: HdmPresetConfig,
  [VTAC_UK_DISTRIBUTOR_KEY]: VtacUkPresetConfig,
  [VTAC_POLAND_DISTRIBUTOR_KEY]: VtacPolandPresetConfig,
}

export const getDistributorConfigByEnum = (distributor: HardwareSupplierEnum | undefined) => {
  const supplierConfig = HARDWARE_SUPPLIER_CONFIGS.find((c) => c.type === distributor)
  return supplierConfig
}

export const getSupplierConfig = (supplier: HardwareSupplierEnum | undefined) => {
  if (supplier === HardwareSupplierEnum.Segen) {
    return SegenPresetConfig
  } else if (supplier === HardwareSupplierEnum.SolarOutlet) {
    return OutletPresetConfig
  } else if (supplier === HardwareSupplierEnum.CityPlumbing) {
    return CityPlumbingPresetConfig
  } else if (supplier === HardwareSupplierEnum.Hdm) {
    return HdmPresetConfig
  } else if (supplier === HardwareSupplierEnum.VtacUk) {
    return VtacUkPresetConfig
  } else if (supplier === HardwareSupplierEnum.VtacPoland) {
    return VtacPolandPresetConfig
  }

  return undefined
}

export const getSupplierEnumByFilterKey = (supplier: HardwareSupplierFilterKeyType): HardwareSupplierEnum =>
  HARDWARE_SUPPLIER_ENUM_MAPPING[supplier]

export const getSupplierConfigByFilterKey = (supplier: HardwareSupplierFilterKeyType): HardwareSupplierPresetConfig =>
  HARDWARE_SUPPLIER_CONFIG_MAPPING[supplier]

export const getCountryDefaultSupplier = (countryIso: string): HardwareSupplierPresetConfig | undefined => {
  if (['AU'].includes(countryIso)) return OutletPresetConfig
  if (['GB', 'UK'].includes(countryIso)) return SegenPresetConfig
  if (['PL'].includes(countryIso)) return VtacPolandPresetConfig
  return undefined
}

export const getCountrySuppliers = (countryIso: string): HardwareSupplierPresetConfig[] | undefined => {
  if (['AU'].includes(countryIso)) {
    return [OutletPresetConfig]
  }
  if (['GB', 'UK', 'JE', 'GG'].includes(countryIso)) {
    return [SegenPresetConfig, CityPlumbingPresetConfig, HdmPresetConfig, VtacUkPresetConfig]
  }
  if (['PL'].includes(countryIso)) {
    return [VtacPolandPresetConfig]
  }
  return undefined
}

export const HARDWARE_SUPPLIER_CONFIGS = [
  SegenPresetConfig,
  OutletPresetConfig,
  CityPlumbingPresetConfig,
  HdmPresetConfig,
  VtacUkPresetConfig,
  VtacPolandPresetConfig,
]
