import { CircularProgress } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    marginLeft: '15px',
  },
}))

const TriggerDailyPaymentEmailsButton: React.FC<PropTypes> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSuperUser = useSelector(authSelectors.getIsSuperUser)
  const classes = useStyles()
  const notify = useNotify()

  const sendDailyPaymentsEmail = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/payments_processing/webhooks/events/trigger_daily_payments_email/',
    })
      .then((res) => {
        console.log('res', res.data)
        notify(JSON.stringify(res.data), 'info')
      })
      .catch((err) => {
        console.log('err')
        notify(`Something went wrong: ${err}`, 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (!isStaff && !isSuperUser) return null
  if (window.ENV === 'production') return null

  return (
    <div className={classes.wrapper}>
      <Button onClick={sendDailyPaymentsEmail} variant="contained" size="small" disabled={isLoading}>
        {isLoading ? <CircularProgress /> : <span>Trigger Daily Payments Email</span>}
      </Button>
    </div>
  )
}

export default TriggerDailyPaymentEmailsButton
