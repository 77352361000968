import { Divider } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { CustomNumberField } from 'elements/field/CustomNumberField'
import _ from 'lodash'
import { Alert, FormControlLabel, Radio, RadioGroup, Switch } from 'opensolar-ui'
import { memo, useCallback, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { useStudioSignals } from 'Studio/signals/useStudioSignals'
import { ModuleGridType, ModuleLayout, PanelConfiguration, StudioItem } from 'types/global'
import { MeasurementUnits } from 'types/orgs'
import { StudioFieldContainer } from '../components/StudioFieldContainer'
import { formatNumber, fromMeters, toMeters } from './helpers'
import RackingSelector from './RackingSelector'
import useStyles from './styles'

type BasicSettingsPropTypes = {
  moduleGrid: ModuleGridType
  allowEdit?: boolean
}

const BasicSettings = memo(({ moduleGrid, allowEdit = false }: BasicSettingsPropTypes) => {
  const [_refresh, setRefresh] = useState<number>(0)

  const translate = useTranslate()
  const classes = useStyles()
  const measurements = useSelector(orgSelectors.getMeasurementUnits) || MeasurementUnits.metric

  const panelConfig = moduleGrid.getPanelConfiguration()
  const isUsingTiltRacks = moduleGrid.isUsingTiltRacks()
  const azimuth = formatNumber(moduleGrid.getAzimuth()) % 360
  const azimuthAuto = moduleGrid.azimuthAuto
  const ridgeDirection = formatNumber(azimuth - 90 < 0 ? 360 + (azimuth - 90) : azimuth - 90) % 360
  const slope = formatNumber(moduleGrid.getSlope())
  const slopeAuto = moduleGrid.slopeAuto
  const panelTilt = _.isNumber(moduleGrid.panelTiltOverride) ? formatNumber(moduleGrid.panelTiltOverride) : 0
  const relativeTilt = formatNumber(panelTilt - slope)
  const moduleOrientation = moduleGrid.moduleLayout()
  const elevationAuto = moduleGrid.elevationAuto
  const groundClearance = _.isNumber(moduleGrid.getGroundClearance())
    ? formatNumber(fromMeters(moduleGrid.getGroundClearance(), measurements))
    : 0
  const isDualTilt = panelConfig === 'DUAL_TILT_RACK'

  const onObjectChanged = useCallback(
    (obj: StudioItem, _prop: string) => {
      if (obj !== moduleGrid) return
      // force a re-render if the module grid has changed
      // wrap the ctr to keep the value small
      setRefresh((prev) => (prev < 999 ? prev + 1 : 0))
    },
    [moduleGrid]
  )

  useStudioSignals(onObjectChanged, ['objectChanged'], undefined, { trackHandler: true })

  const setAzimuth = (value: number): void => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        moduleGrid,
        'azimuth',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }

  const setAzimuthAuto = (value: boolean): void => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        moduleGrid,
        'azimuthAuto',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }

  const setSlope = (value: number): void => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(moduleGrid, 'slope', value, window.Utils.generateCommandUUIDOrUseGlobal())
    )
    window.SceneHelper.snapModuleGridToGroundLevel(moduleGrid)
  }

  const setSlopeAuto = (value: boolean): void => {
    if (value === true && !isUsingTiltRacks) {
      moduleGrid.panelTiltOverride = null
    }
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        moduleGrid,
        'slopeAuto',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }

  const setOrientation = (value: ModuleLayout): void => {
    window.editor.execute(new window.RedrawModulesCommand(moduleGrid, 'moduleLayout', value, moduleGrid.moduleLayout()))
  }

  const setPanelConfig = (value: PanelConfiguration): void => {
    if (value === panelConfig) return // no change

    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        moduleGrid,
        'panelConfiguration',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )

    if (value === 'STANDARD') {
      window.editor.execute(
        new window.SetPanelConfigurationCommand(
          moduleGrid,
          'panelTiltOverride',
          null,
          window.Utils.generateCommandUUIDOrUseGlobal()
        )
      )
    } else if (moduleGrid.panelTiltOverride === null) {
      window.editor.execute(
        new window.SetPanelConfigurationCommand(
          moduleGrid,
          'panelTiltOverride',
          slope + 20,
          window.Utils.generateCommandUUIDOrUseGlobal()
        )
      )
    }
  }

  const setPanelTiltOverride = (value: number): void => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        moduleGrid,
        'panelTiltOverride',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }

  const setElevationAuto = (value: boolean): void => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        moduleGrid,
        'elevationAuto',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }

  const setGroundClearance = (value: number): void => {
    if (moduleGrid.getGroundClearance() !== value) {
      window.editor.execute(
        new window.SetPanelConfigurationCommand(moduleGrid, 'groundClearance', toMeters(value, measurements))
      )
    }
  }

  return (
    <>
      <div className={classes.fieldsRow} style={{ marginBottom: 10 }}>
        <StudioFieldContainer
          label={translate('Racking')}
          style={{ width: '100%' }}
          field={
            <RackingSelector
              value={panelConfig}
              onChange={(selected) => {
                moduleGrid.modulesPerRow(selected === 'DUAL_TILT_RACK' ? 2 : 1)
                setPanelConfig(selected)
              }}
            />
          }
        />
      </div>

      {isDualTilt && (
        <Alert severity="info" style={{ fontSize: '0.85em', padding: 10, marginBottom: 15 }}>
          {translate("To customize spacing and framing of dual-tilt systems, tap the 'Advanced' tab.")}
        </Alert>
      )}

      <div className={classes.fieldsRow}>
        {!isDualTilt && (
          <StudioFieldContainer
            style={{ width: '50%' }}
            label={translate('Azimuth')}
            field={
              <CustomNumberField
                name="moduleGridAzimuth"
                value={azimuth}
                endAdornment="°"
                disabled={!allowEdit || azimuthAuto}
                minValue={0}
                maxValue={360}
                maxDecimalPlaces={3}
                wrapperStyles={{ marginBottom: 0 }}
                onChange={(newValue, event) => {
                  setAzimuth(newValue)
                }}
                allowReEnable={allowEdit}
                onEditabilityChange={(isEditable) => {
                  setAzimuthAuto(!isEditable)
                }}
                reEnableBtnTitleOnDisabled={translate('Override azimuth')}
                reEnableBtnTitleOnEnabled={translate('Remove azimuth override')}
              />
            }
          />
        )}

        {isDualTilt && (
          <StudioFieldContainer
            style={{ width: '50%' }}
            label={translate('Ridge Direction')}
            field={
              <CustomNumberField
                name="moduleGridRidgeDirectionk"
                value={ridgeDirection}
                endAdornment="°"
                disabled={!allowEdit || azimuthAuto}
                minValue={0}
                maxValue={360}
                maxDecimalPlaces={3}
                wrapperStyles={{ marginBottom: 0 }}
                onChange={(newValue, event) => {
                  setAzimuth(newValue + 90)
                }}
                allowReEnable={allowEdit}
                onEditabilityChange={(isEditable) => {
                  setAzimuthAuto(!isEditable)
                }}
                reEnableBtnTitleOnDisabled={translate('Override ridge direction')}
                reEnableBtnTitleOnEnabled={translate('Remove ridge direction override')}
              />
            }
          />
        )}

        <StudioFieldContainer
          style={{ width: '50%' }}
          label={translate('Slope')}
          field={
            <CustomNumberField
              name="moduleGridSlope"
              value={slope}
              endAdornment="°"
              disabled={!allowEdit || slopeAuto || isUsingTiltRacks}
              minValue={0}
              maxValue={360}
              maxDecimalPlaces={3}
              wrapperStyles={{ marginBottom: 0 }}
              onChange={(newValue, _event) => {
                // do not update panel tilt override unnecessarily
                // if we detect that module grid is not actually using tilt racks
                if (isUsingTiltRacks && moduleGrid.panelTiltOverride !== null) {
                  moduleGrid.panelTiltOverride = relativeTilt + newValue
                }
                setSlope(newValue)
              }}
              allowReEnable={allowEdit}
              onEditabilityChange={(isEditable) => {
                setSlopeAuto(!isEditable)
              }}
              reEnableBtnTitleOnDisabled={translate('Override slope')}
              reEnableBtnTitleOnEnabled={translate('Remove slope override')}
            />
          }
        />
      </div>

      {isUsingTiltRacks && (
        <div className={classes.fieldsRow}>
          <StudioFieldContainer
            style={{ width: '50%' }}
            label={translate('Relative Tilt')}
            info={translate("The panels' angle of tilt relative to the surface slope")}
            field={
              <CustomNumberField
                name="moduleGridPanelTiltOverride"
                value={relativeTilt}
                endAdornment="°"
                disabled={!allowEdit}
                minValue={panelConfig === 'TILT_RACK' ? -360 : 0}
                maxValue={360}
                maxDecimalPlaces={3}
                wrapperStyles={{ marginBottom: 0 }}
                onChange={(newValue, _event) => {
                  setPanelTiltOverride(newValue + slope)
                }}
              />
            }
          />
          <div style={{ width: '50%' }}></div>
        </div>
      )}

      <div className={classes.fieldsRow}>
        <StudioFieldContainer
          label={translate('Panel Orientation')}
          field={
            <RadioGroup
              name="moduleLayout"
              value={moduleOrientation}
              row
              style={{ marginTop: 10 }}
              onChange={(_event, value) => setOrientation(value as ModuleLayout)}
            >
              <FormControlLabel
                disabled={!allowEdit}
                value={'portrait'}
                control={<Radio color="default" className={classes.simpleRadioButton} />}
                label={<span style={{ fontSize: 13 }}>{translate('Portrait')}</span>}
              />
              <FormControlLabel
                disabled={!allowEdit}
                value={'landscape'}
                control={<Radio color="default" className={classes.simpleRadioButton} />}
                label={<span style={{ fontSize: 13 }}>{translate('Landscape')}</span>}
              />
            </RadioGroup>
          }
        />
      </div>

      <Divider style={{ marginTop: 15, marginBottom: 15 }} />

      <div className={classes.fieldsRow} style={{ alignItems: 'start', marginBottom: 5 }}>
        <StudioFieldContainer
          style={{ width: '50%' }}
          label={translate('Auto-Elevation')}
          field={
            <Switch
              disabled={!allowEdit}
              checked={elevationAuto}
              onChange={(_event, checked) => {
                setElevationAuto(checked)
              }}
            ></Switch>
          }
        />
        <StudioFieldContainer
          style={{ width: '50%' }}
          label={translate('Ground Clearance')}
          field={
            <CustomNumberField
              name="moduleGridGroundClearance"
              value={groundClearance}
              endAdornment={measurements === 'imperial' ? 'in' : 'm'}
              step={measurements === 'imperial' ? 0.5 : 0.1} // 0.5in for imperial or 0.1m for metric
              disabled={!allowEdit}
              minValue={0}
              maxDecimalPlaces={4}
              wrapperStyles={{ marginBottom: 0 }}
              resettable={true}
              onChange={(newValue, _event) => {
                setGroundClearance(newValue)
              }}
            />
          }
        />
      </div>
    </>
  )
})

export default BasicSettings
