import { HardwareSupplierEnum } from 'pages/ordering/type'

interface Props {
  hardwareSupplier?: HardwareSupplierEnum
}

const REGISTER_LINK_MAPPING: {
  [key in Exclude<HardwareSupplierEnum, | HardwareSupplierEnum.Hdm | HardwareSupplierEnum.VtacUk | HardwareSupplierEnum.VtacPoland>]: string
} = {
  [HardwareSupplierEnum.SolarOutlet]: 'https://pages.opensolar.com/partners/solar-outlet#Getstarted',
  [HardwareSupplierEnum.Segen]: 'https://share.hsforms.com/1r-gtFaxoQj-vksZvB23FFAcne2p',
  [HardwareSupplierEnum.CityPlumbing]: 'https://share.hsforms.com/1ArJCBRFsSReMQzJtbzqw7gcne2p',
}

const RegisterLink: React.FC<Props> = ({ hardwareSupplier = HardwareSupplierEnum.SolarOutlet }) => (
  <span>
    <a href={REGISTER_LINK_MAPPING[hardwareSupplier]} target="_blank" rel="noreferrer">
      Register
    </a>{' '}
    to view pricing and stock availability
  </span>
)

export default RegisterLink
