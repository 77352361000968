import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { COLOR_PALETTE, getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'
import { StageActionEventType } from 'types/projects'
import { Theme } from 'types/themes'
import { formatDate } from 'util/date'
import ActionButtons from './events/ActionButtons'
import EventIcon from './events/EventIcon'
import TaskStatus from './events/TaskStatus'
import { EVENT_HIDE_NOTES } from './util'
const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    color: COLOR_PALETTE.darkGrey,
    fontWeight: 500,
  },
  eventWrapper: {
    paddingTop: 16,
    paddingBottom: 24,
    borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
    '&.last': {
      borderBottom: 'none',
    },
  },
  eventWrapperMin: {
    marginBottom: 20,
    '&.last': {
      marginBottom: 0,
    },
  },
  eventTitle: {
    margin: 0,
    fontWeight: 500,
    '&.min': {
      fontWeight: 400,
    },
  },
  timeStamp: {
    fontSize: 12,
    color: COLOR_PALETTE.iconColor,
    fontWeight: 400,
    marginTop: 2,
  },
  notes: {
    display: 'flex',
    maxHeight: 65,
    overflowY: 'auto',
    borderRadius: 8,
    padding: 12,
    backgroundColor: COLOR_PALETTE.grey2,
    color: COLOR_PALETTE.iconColor,
    '& p': {
      margin: 0,
    },
  },
  fullWidth: {
    width: '100%',
    gap: 6,
    marginLeft: 10,
  },
}))

interface DisplayProps {
  title?: string
  events: StageActionEventType[]
  toggleModal: (event: StageActionEventType) => void
  toggleDelete: (event: StageActionEventType) => void
  minDisplay?: boolean
}

const EventsDisplay: React.FC<DisplayProps> = ({ title, events, toggleModal, toggleDelete, minDisplay }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const currentDate = moment()

  return (
    <div className={sectionStyles.column}>
      {title && <p className={classes.sectionTitle}>{translate(title)}</p>}
      {!!events.length &&
        events.map((event, i) => {
          const dayDifference = currentDate.diff(moment(event.created_date), 'days')
          if (minDisplay)
            return (
              <div
                key={'event-' + i}
                className={`${sectionStyles.row} ${classes.eventWrapperMin} ${i === events.length - 1 ? 'last' : ''}`}
              >
                <EventIcon event={event} minDisplay={minDisplay} />
                <div className={`${sectionStyles.column} ${classes.fullWidth}`}>
                  <div className={sectionStyles.row}>
                    <div className={sectionStyles.column}>
                      <p className={`${classes.eventTitle} min`}>{event.title}</p>
                      <span className={`${classes.timeStamp}`}>
                        {dayDifference > 2
                          ? formatDate(moment(event.created_date))
                          : moment(event.created_date).fromNow()}{' '}
                        {event.event_icon && event.who?.display && `by ${event.who.display}`}
                      </span>
                    </div>
                    <ActionButtons
                      minDisplay={minDisplay}
                      toggleDelete={toggleDelete}
                      toggleModal={toggleModal}
                      event={event}
                    />
                  </div>
                  <TaskStatus event={event} minDisplay={minDisplay} />
                  {event.notes && (!EVENT_HIDE_NOTES.includes(event.form_config) || event.event_type_id === 32) && (
                    <div className={classes.notes}>
                      <p style={{ whiteSpace: 'pre-line' }}>{event.notes}</p>
                    </div>
                  )}
                </div>
              </div>
            )
          return (
            <div
              key={'event-' + i}
              className={`${sectionStyles.row} ${classes.eventWrapper} ${i === events.length - 1 ? 'last' : ''}
              }`}
            >
              <EventIcon event={event} minDisplay={minDisplay} />
              <div className={`${sectionStyles.column} ${classes.fullWidth}`}>
                <div className={sectionStyles.row}>
                  <div className={sectionStyles.column}>
                    <p className={classes.eventTitle}>{event.title} </p>
                    <span className={classes.timeStamp}>
                      {dayDifference > 2
                        ? formatDate(moment(event.created_date))
                        : moment(event.created_date).fromNow()}{' '}
                      {event.event_icon && event.who?.display && `by ${event.who.display}`}
                    </span>
                  </div>
                  <ActionButtons
                    minDisplay={minDisplay}
                    toggleDelete={toggleDelete}
                    toggleModal={toggleModal}
                    event={event}
                  />
                </div>
                <TaskStatus event={event} />
                {event.notes && (!EVENT_HIDE_NOTES.includes(event.form_config) || event.event_type_id === 32) && (
                  <div className={classes.notes}>
                    <p style={{ whiteSpace: 'pre-line' }}>{event.notes}</p>
                  </div>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default EventsDisplay
