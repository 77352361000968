import CustomField from 'elements/field/CustomField'
import { styled } from 'opensolar-ui'
import { useMemo } from 'react'
import { SelectInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { FilterField, OS_PROJECT_FILTERS } from './fields'

interface PropTypes {
  source: string
  filter: FilterField
  filtersAdded: string[]
}

const FilterRow = styled('div')({
  display: 'flex',
  width: '100%',
  gap: 10,
})

const Placeholder = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}))

const FilterDisplay: React.FC<PropTypes> = ({ source, filter, filtersAdded }) => {
  const form = useForm()
  const translate = useTranslate()

  const filteredOptions = useMemo(() => {
    return OS_PROJECT_FILTERS.filter((filterOption) => {
      const sameFilterSelections = filtersAdded.filter((id) => id === filterOption.id).length
      const totalOperators = filterOption.relatedFields?.length || 1

      return sameFilterSelections < totalOperators || filter.fieldId === filterOption.id
    }).sort((a, b) => a.name.localeCompare(b.name))
  }, [filter.fieldId, filtersAdded])

  const fieldData = useMemo(() => OS_PROJECT_FILTERS.find((x) => x.id === filter.fieldId), [filter.fieldId])

  const selectedValues = useMemo(() => {
    return filtersAdded.reduce<string[]>((acc, fieldId) => {
      const filterField = form.getFieldState(`${source}.value`)
      if (filterField?.value) {
        return [...acc, ...(Object.values(filterField.value) as string[])]
      }
      return acc
    }, [])
  }, [filtersAdded, form, source])

  return (
    <FilterRow>
      <SelectInput
        source={`${source}.fieldId`}
        label={false}
        variant="outlined"
        choices={filteredOptions}
        margin="none"
        onChange={(e) => {
          // Clear field values when a new filter is selected
          form.change(`${source}.fields`, {})
          form.change(`${source}.value`, {})
        }}
        SelectProps={{
          displayEmpty: true,
          native: undefined,
          renderValue: (value) => {
            const findItem = filteredOptions.find((x: any) => x.id === value)
            return findItem?.name || <Placeholder>{translate('Select filter')}</Placeholder>
          },
        }}
      />
      {fieldData?.field && (
        <CustomField
          source={source}
          name={source}
          component={fieldData?.field}
          fieldId={fieldData.id}
          {...(fieldData.props || {})}
          selectedValues={selectedValues}
        />
      )}
    </FilterRow>
  )
}

export default FilterDisplay
