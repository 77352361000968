import { usePromos } from 'persistentContent/inAppPromo/hooks/usePromos'
import { useEffect, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import { useForm } from 'react-final-form'
import { generateUUID } from 'util/misc'
import { RecommendBatterySetType, RecommendedBatterySetsRequestParams } from '../utility/types'

type PropsType = {
  params: RecommendedBatterySetsRequestParams
  projectId: number
  systemUuid: string
}

const getUnsavedData = (systemUuid: string) => {
  // Try to get unsaved project data and design data
  try {
    const restPostData = window.projectForm ? window.projectForm.mutators.getUnsavedChangeAffectSystemCalcs() : {}
    const sceneJSONFiltered = window.editor?.sceneSystemsOnlyAsJSON([systemUuid], true)

    // Inject system_current into each system, if found
    var system_current_matches = window.editor?.filterObjects(function (s) {
      return s.type === 'OsSystem' && s.userData.is_current === true
    })
    if (system_current_matches.length > 0) {
      for (var i = 0; i < sceneJSONFiltered['object']['children'].length; i++) {
        var s = sceneJSONFiltered['object']['children'][i]
        if (s.userData.is_current !== true) {
          s.userData.system_current = system_current_matches[0].userData
        }
      }
    }
    return {
      ...restPostData,
      design: sceneJSONFiltered && window.CompressionHelper.compress(JSON.stringify(sceneJSONFiltered)),
    }
  } catch (e) {
    console.error('useFetchRecommendedBatterySets: Failed to get unsaved data', e)
    return {}
  }
}

const useFetchRecommendedBatterySets = ({
  params,
  projectId,
  systemUuid,
}: PropsType): {
  isLoading: boolean
  recommendedBatterySets: RecommendBatterySetType[]
} => {
  const [isLoading, setIsLoading] = useState(true)
  const [recommendedBatterySets, setRecommendedBatterySets] = useState([])

  const { promos, state: promosLoadingState } = usePromos('sponsored_content')
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // Create dict mapping sponsored battery codes to weighting
  const sponsoredBatteryCodesWeightMap = {}
  promos
    .filter((promo) => promo.sponsoredContent!.component_codes)
    .forEach(({ sponsoredContent }) => {
      sponsoredContent!.component_codes.forEach(
        (code) => (sponsoredBatteryCodesWeightMap[code] = sponsoredContent!.weighting)
      )
    })

  if (Object.keys(sponsoredBatteryCodesWeightMap).length) {
    // TODO: move this map to BE once this PR is merged https://github.com/open-solar/sunsuite/pull/8215
    params['sponsored_batteries'] = sponsoredBatteryCodesWeightMap
  }

  const form = useForm()
  useEffect(() => {
    if (promosLoadingState !== 'not-loaded') {
      if (form.mutators.getFormDirtyFields().length > 0) {
        // BDA encounters some issues when the system has some unsaved changes (see https://github.com/open-solar/opensolar-todo/issues/13202)
        // To resolve this, include unsaved system data and design data (if there is any)
        params = {
          ...params,
          ...getUnsavedData(systemUuid),
          include_unsaved_project: true,
        }
      }

      dataProvider
        .CUSTOM_POST(
          'recommend_battery_sets',
          {
            url: `projects/${projectId}/systems/${systemUuid}/recommend_battery_sets/`,
            data: params,
          },
          {
            onSuccess: ({ data }) => {
              setIsLoading(false)
              setRecommendedBatterySets(
                data.map((batterySet) => ({
                  ...batterySet,
                  batteries: batterySet.batteries.map((battery) => ({ ...battery, type: 'battery' })),
                  id: generateUUID(),
                }))
              )
            },
          }
        )
        .catch((error) => {
          setIsLoading(false)
          notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
        })
    }
  }, [promosLoadingState])

  return { isLoading, recommendedBatterySets }
}

export default useFetchRecommendedBatterySets
