import { doNotTranslate } from 'util/misc'

export const PERFORMANCE_CALCULATORS_PVWATTS_V6 = 1
export const PERFORMANCE_CALCULATORS_SAM = 2
export const PERFORMANCE_CALCULATORS_MCS = 3
export const PERFORMANCE_CALCULATORS_PVWATTS_V8 = 4
export const PERFORMANCE_CALCULATORS_3RD_PARTY = 5

export type PERFORMANCE_CALCULATOR_ID =
  | typeof PERFORMANCE_CALCULATORS_PVWATTS_V6
  | typeof PERFORMANCE_CALCULATORS_SAM
  | typeof PERFORMANCE_CALCULATORS_MCS
  | typeof PERFORMANCE_CALCULATORS_PVWATTS_V8
  | typeof PERFORMANCE_CALCULATORS_3RD_PARTY

export const PERFORMANCE_CALCULATORS = [
  {
    id: PERFORMANCE_CALCULATORS_SAM,
    name: 'System Advisor Model 2020.02.29.r2',
    translate: false,
    supports_shading: true,
    supports_automated_shading: true,
  },
  {
    id: PERFORMANCE_CALCULATORS_MCS,
    name: 'MCS (UK Only)',
    translate: false,
    restrict_to_country: ['GB'],
    supports_automated_shading: true,
  },
  {
    id: PERFORMANCE_CALCULATORS_PVWATTS_V8,
    name: 'PVWatts v8',
    translate: false,
    supports_automated_shading: false,
  },
  // PVWatts v6 has been decommissioned in early 2024 and has been replaced by PVWatts v8: https://developer.nrel.gov/docs/solar/pvwatts/v8/
  {
    id: PERFORMANCE_CALCULATORS_PVWATTS_V6,
    name: 'PVWatts v6 (Deprecated)',
    translate: false,
    supports_automated_shading: false,
    hide_from_choices: true,
  },
  {
    id: PERFORMANCE_CALCULATORS_3RD_PARTY,
    name: 'Import 3rd Party',
    translate: true,
    supports_automated_shading: false,
    hide_from_choices: false,
  },
]

export function supportsAnyShading(calculatorId: PERFORMANCE_CALCULATOR_ID): boolean {
  return calculatorId === 1 || calculatorId === 3 || calculatorId === 4
}

export const getPerformanceCalculatorChoices = (selectedCalculator: number, country_iso2: string) => {
  return PERFORMANCE_CALCULATORS.filter((calculator) => {
    // Continue showing the calculator option that should be hidden if it was already previously selected,
    // otherwise that calculator option is hidden and cannot be selected.
    if (calculator?.hide_from_choices === true && selectedCalculator !== calculator.id) {
      return false
    } else if (calculator.restrict_to_country) {
      return calculator.restrict_to_country.includes(country_iso2)
    } else {
      return true
    }
  })
}

export const translateCalculators = (calculators, translate: (term: string) => string) => {
  return calculators.map((pc) => ({
    ...pc,
    name: pc.translate ? translate(pc.name) : doNotTranslate(pc.name),
  }))
}
