import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add } from '@material-ui/icons'
import CustomField from 'elements/field/CustomField'
import Tooltip from 'elements/tooltip/Tooltip'
import { useCountryListsTranslated } from 'hooks/useCountryListsTranslated'
import { BaseThemeType } from 'opensolar-ui'
import CustomerBadgeTooltipContent from 'projectSections/sections/manage3/common/CustomerBadgeTooltipContent'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { ANON_SHARE_CONTACT } from 'types/contacts'
import { Theme } from 'types/themes'
import HoverEditWrapper from '../../../common/HoverEditWrapper'
import { COLOR_PALETTE, getProjectSectionStyles } from '../../../styles'
import AddContactBtn from './AddContactButton'
import ContactsFormContent from './ContactsFormContent'

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    minHeight: 30,
    display: 'flex',
    alignItems: 'center',
    color: COLOR_PALETTE.darkGrey,
    marginTop: 8,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  displayText: {
    color: COLOR_PALETTE.darkBlue,
    '& .MuiSvgIcon-root': {
      height: 18,
    },
  },
  addBtn: {
    margin: '0 !important',
    marginTop: '8px !important',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  separator: {
    color: COLOR_PALETTE.darkBlue,
  },
  tooltip: {
    backgroundColor: 'white !important',
    padding: '10px 0px 5px 0px',
    minWidth: '255px',
  },
}))
const parsePhonePrefix = (data, countries) => {
  if (!data) return []
  return data.map((x) => {
    let contact = { ...x }
    const phone = x.phone?.trim()
    const match = phone?.match(/\(([^()]+)\)/g)
    if (!!match?.length) {
      const countryCodeMatch = match[0]
      contact.phone = phone.replace(countryCodeMatch, '')
      let prefix = match[0].replace('(', '').replace(')', '').replace('+', '')
      if (!contact?.custom_data?.os_meta?.phone_country_iso2) {
        const countryMatches = countries.filter((y) => y.prefix === prefix)
        if (countryMatches && countryMatches.length === 1) {
          //only add prefix if one match is found
          const match = countryMatches[0]
          contact.custom_data = {
            ...(contact.custom_data || {}),
            os_meta: {
              ...(contact.custom_data?.os_meta || {}),
              phone_country_iso2: match.iso2,
            },
          }
        }
      }
    }
    return contact
  })
}

const validateContactForm = (values) => {
  let errors = {}
  if (!!values.contacts_data?.length) {
    let contactEmails = values.contacts_data.map((x) => x?.email)
    let tempEmails: string[] = []
    let duplicates: string[] = []
    contactEmails.forEach((email) => {
      if (tempEmails.includes(email)) {
        duplicates.push(email)
      } else {
        tempEmails.push(email)
      }
    })
    if (!!duplicates.length) {
      errors['contacts_data'] = values.contacts_data.map((x) =>
        x?.email && duplicates.includes(x?.email) ? { email: 'Please use unique emails for each contact.' } : {}
      )
    }
  }

  return errors
}

const ProjectContacts = () => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const { countriesAll } = useCountryListsTranslated()
  const contacts = useMemo(() => project.contacts_data?.filter((x) => x.type !== ANON_SHARE_CONTACT), [
    project.contacts_data,
  ])
  const [activeContact, setActiveContact] = useState<number | null>()

  const getContactName = (contact, index) => {
    return !contact || (!contact?.first_name && !contact.family_name)
      ? contact?.id
        ? `${translate('Contact')} ${contact.id}`
        : `${translate('Contact')} #${index + 1}`
      : `${contact?.first_name || ''} ${contact?.family_name || ''}`
  }
  const renderContactName = (contact, index, count) => {
    let fullName =
      contact.first_name && contact.family_name
        ? `${contact.first_name} ${contact.family_name}`
        : getContactName(contact, index)
    return count > 1 ? (fullName.length > 17 ? `${fullName.substring(0, 15)}...` : fullName) : fullName
  }

  useEffect(() => {
    const filteredList = project.contacts_data?.filter((x) => x !== undefined)
    if (!!filteredList?.length && filteredList?.length !== project.contacts_data?.length) {
      form.change('contacts_data', filteredList)
    }
  }, [project.contacts_data])

  const hasContactInfo = (contact) => {
    return contact?.email || contact?.phone
  }
  const activeContactInfo = contacts ? contacts[activeContact ?? 0] : {}
  const shouldShowTooltip = hasContactInfo(activeContactInfo)
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))
  const contactsClasses = getProjectSectionStyles()

  return (
    <HoverEditWrapper
      modalProps={{
        initialValues: {
          ...project,
          contacts_data: !contacts?.length ? [{}] : parsePhonePrefix(contacts, Object.values(countriesAll)),
        },
        headerText: translate('Contacts'),
        content: <CustomField source={'contacts_data'} name={'contacts_data'} component={ContactsFormContent} />,
        validate: validateContactForm,
        toolbarBtn: (formProps) => <AddContactBtn {...formProps} />,
      }}
      className={
        isMobile ? contactsClasses.mobileViewDiv : `${contactsClasses.detail} ${contactsClasses.contactsCustomWidth}`
      }
      titleElement={<p>{translate('Contact')}</p>}
      emptyToggle={
        !!!contacts?.length
          ? {
              label: <>{translate('Add Contact')}</>,
              startIcon: <Add />,
              className: classes.addBtn,
            }
          : undefined
      }
    >
      <>
        <Tooltip
          title={shouldShowTooltip ? <CustomerBadgeTooltipContent activeContactInfo={activeContactInfo} /> : ''}
          interactive={true}
          classes={{ tooltip: classes.tooltip }}
        >
          <div className={classes.contentWrapper}>
            {contacts && !!contacts.length && (
              <>
                {contacts.length === 1 && (
                  <span className={`${classes.displayText}`} onMouseEnter={() => setActiveContact(0)}>
                    {renderContactName(contacts[0], 0, contacts.length)}
                  </span>
                )}
                {isMobile ? (
                  <>
                    {contacts.length > 1 && (
                      <>
                        <span className={`${classes.displayText}`} onMouseEnter={() => setActiveContact(0)}>
                          {renderContactName(contacts[0], 0, contacts.length)}
                        </span>
                        <span className={classes.separator}>,&nbsp;</span>
                        <span className={classes.separator}>&nbsp;+&nbsp;</span>
                        <span className={`${classes.displayText}`} onMouseEnter={() => setActiveContact(-1)}>
                          {contacts.length - 1}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {contacts.length === 2 && (
                      <>
                        <span className={`${classes.displayText}`} onMouseEnter={() => setActiveContact(0)}>
                          {renderContactName(contacts[0], 0, contacts.length)}
                        </span>
                        <span className={classes.separator}>&nbsp;&&nbsp;</span>
                        <span className={`${classes.displayText}`} onMouseEnter={() => setActiveContact(1)}>
                          {renderContactName(contacts[1], 1, contacts.length)}
                        </span>
                      </>
                    )}
                    {contacts.length > 2 && (
                      <>
                        <span className={`${classes.displayText}`} onMouseEnter={() => setActiveContact(0)}>
                          {renderContactName(contacts[0], 0, contacts.length)}
                        </span>
                        <span className={classes.separator}>,&nbsp;</span>
                        <span className={`${classes.displayText}`} onMouseEnter={() => setActiveContact(1)}>
                          {renderContactName(contacts[1], 1, contacts.length)}
                        </span>
                        <span className={classes.separator}>&nbsp;+&nbsp;</span>
                        <span className={`${classes.displayText}`} onMouseEnter={() => setActiveContact(-1)}>
                          {contacts.length - 2}
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Tooltip>
      </>
    </HoverEditWrapper>
  )
}
export default ProjectContacts
