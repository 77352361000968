import { Divider, Paper } from '@material-ui/core'
import { UiSwitch } from 'elements/UiSwitch'
import { ToggleButton, ToggleButtonGroup } from 'opensolar-ui'
import Panel from 'projectSections/sections/design/Panel'
import BuildablePanels from 'projectSections/sections/design/systems/tabs/panels/BuildablePanels'
import ComponentWarningBox from 'projectSections/sections/design/systems/warning/ComponentWarningBox'
import { useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { ModuleGridType, StudioItem } from 'types/global'
import ExpansionPanelShading from '../ExpansionPanelShading'
import { handleSetShadingOverride, shadingTypeFromShadingOverride } from '../ShadingControls'
import AdvancedSettings from './AdvancedSettings'
import BasicSettings from './BasicSettings'
import useStyles from './styles'

type PanelModuleGridPropTypes = {
  moduleGrid: ModuleGridType
  allowEdit?: boolean
}

enum TabKeys {
  Basic = 'basic',
  Advanced = 'advanced',
}

const PanelModuleGrid = ({ moduleGrid, allowEdit = false }: PanelModuleGridPropTypes) => {
  const [_refresh, setRefresh] = useState(0)

  const [moduleCount, setModuleCount] = useState<number>(moduleGrid.moduleQuantity())
  const moduleGridIndex = moduleGrid.getSystem().moduleGrids().indexOf(moduleGrid)

  const [shadingOverride, setShadingOverride] = useState<number[]>(moduleGrid.shadingOverride || [])
  const [shadingOverrideType, setShadingOverrideType] = useState<string>(
    shadingTypeFromShadingOverride(moduleGrid.shadingOverride)
  )

  const translate = useTranslate()
  const classes = useStyles()

  const [activeTab, setActiveTab] = useState<TabKeys>(TabKeys.Basic)

  const refreshShadingData = () => {
    setShadingOverride(moduleGrid.shadingOverride || [])
    setShadingOverrideType(shadingTypeFromShadingOverride(moduleGrid.shadingOverride))
  }

  useEffect(() => {
    setModuleCount(moduleGrid.moduleQuantity())
    refreshShadingData()
  }, [moduleGrid])

  const onObjectChanged = useCallback(
    (o: StudioItem, prop: string) => {
      if (o === moduleGrid && prop === 'cellsActive') {
        setModuleCount(moduleGrid.moduleQuantity())
      }
    },
    [moduleGrid]
  )

  const onShadingUpdated = useCallback(
    (o: StudioItem) => {
      if (o !== moduleGrid) return
      refreshShadingData()
    },
    [moduleGrid]
  )

  useStudioSignalsLazy(onObjectChanged, ['objectChanged'], undefined, { trackHandler: true })

  useStudioSignalsLazy(onShadingUpdated, ['shadingUpdated'], undefined, { trackHandler: true })

  useStudioSignalsLazy(
    (type: string, animation: string) => {
      if (type === 'tween' && animation === 'animateSun') {
        setRefresh((prev) => (prev < 99 ? prev + 1 : 0)) // force re-render
      }
    },
    ['animationStop']
  )

  return (
    <Panel
      showTools={allowEdit}
      state={{ allowEdit: allowEdit }}
      selectedObject={moduleGrid}
      title={translate('Panel Group') + ' ' + (moduleGridIndex + 1)}
      summary={
        <div>
          {moduleCount} {translate('modules')}
        </div>
      }
      content={
        <div>
          <ComponentWarningBox componentIds={[moduleGrid.uuid]} />
          <UiSwitch uiKey="studio.tabs.selected_panel_group.basic_settings">
            <Paper className={classes.fieldsContainer}>
              <div style={{ padding: 10 }}>
                <ToggleButtonGroup
                  fullWidth={true}
                  exclusive
                  value={activeTab}
                  onChange={(e, value) => {
                    if (!value) return
                    setActiveTab(value)
                  }}
                >
                  <ToggleButton value="basic">{translate('Basic Settings')}</ToggleButton>
                  <UiSwitch uiKey="studio.tabs.selected_panel_group.advanced_settings">
                    <ToggleButton value="advanced">{translate('Advanced')}</ToggleButton>
                  </UiSwitch>
                </ToggleButtonGroup>
              </div>
              <Divider />
              <div style={{ padding: '15px 10px 0px 10px' }}>
                {activeTab === TabKeys.Basic && <BasicSettings moduleGrid={moduleGrid} allowEdit={allowEdit} />}
                {activeTab === TabKeys.Advanced && <AdvancedSettings moduleGrid={moduleGrid} allowEdit={allowEdit} />}
              </div>
            </Paper>
          </UiSwitch>
          <UiSwitch uiKey="studio.tabs.selected_panel_group.shading">
            <ExpansionPanelShading
              // @TODO: this 'setStateParent' prop isn't strictly necessary here
              // it just complicates things needlessly. just handle the state in the subcomponent
              setStateParent={(newState: { shadingOverride: number[] }) => {
                setShadingOverride(newState.shadingOverride)
                setShadingOverrideType(shadingTypeFromShadingOverride(newState.shadingOverride))
              }}
              object={moduleGrid}
              state={{ shadingOverrideType, shadingOverride }}
              handleSetShadingOverride={handleSetShadingOverride}
              modulesForShadingGrid={moduleGrid.getModules()}
              diffuseShading={moduleGrid.diffuseShading}
              allowEdit={allowEdit}
            />
          </UiSwitch>
          <UiSwitch uiKey="studio.tabs.selected_panel_group.buildable_panels">
            {moduleGrid.getSystem() && (
              <BuildablePanels disabled={!allowEdit} system={moduleGrid.getSystem()} moduleGrid={moduleGrid} />
            )}
          </UiSwitch>
        </div>
      }
    />
  )
}

export default PanelModuleGrid
