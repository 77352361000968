import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined'
import { userRegister } from 'actions/authActions'
import { countriesWhichRequireTermsOptIn, countryMapping } from 'constants/countries'
import { SERVICE_OFFERING_OPTIONS } from 'constants/sectors'
import Button from 'elements/button/Button'
import CustomField from 'elements/field/CustomField'
import ImageFieldClearable from 'elements/field/ImageFieldClearable'
import GDPRText from 'elements/GDPRText'
import CheckboxInput from 'elements/input/CheckboxInput'
import CheckboxMultiSelectInput from 'elements/input/CheckboxMultiSelectInput'
import { ColorInput } from 'elements/input/ColorInput'
import { TermsAndPrivacyLinks } from 'elements/legal/TermsAndPrivacyLinks'

import { useCountryListsTranslated } from 'hooks/useCountryListsTranslated'
import React, { useEffect, useState } from 'react'
import { FormDataConsumer, ImageField, ImageInput, useTranslate } from 'react-admin'
import { Field, Form, useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ShowHighLightColor } from 'resources/orgContent/ContentEdit'
import { getQueryParams, loadQueryVariable } from 'util/query'
import { composeValidators, emailFormat, required, requiredOrZero } from 'validations'
import { validateHexColor } from 'validations/Orgs'
import { renderInput } from '../../common'
import PasswordInput from '../fields/PasswordInput'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 280,
    padding: '0.5em 1em 1em 1em',
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
    [theme.breakpoints.up('lg')]: {
      width: 900,
      padding: '1.5em 1.5em 0.75em',
    },
  },
  emailConsent: {},
  logoWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  terms: {
    fontSize: '12px',
    textAlign: 'center',
  },
  extraCheckboxRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    '& label': {
      marginRight: 3,
      lineHeight: '150%',
    },
  },
  hint: {
    margin: '30px 5px 5px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    marginTop: '1em',
    color: '#4D4D4D',
    fontSize: '11pt',
  },
  extraWrapper: {
    display: 'flex',
    background: '#F5F5F5',
    borderRadius: '8px',
    padding: '0 10px',
    margin: '12px 0',
  },
  link: {
    '&:hover': {
      color: 'rgba(0,0,0)',
    },
  },
  input: {
    width: 275,
    marginLeft: 'auto',
    marginRight: 'auto',
    '& img': {
      maxHeight: 50,
      maxWidth: 200,
    },
    '& .MuiFormHelperText-root': {
      marginTop: 0,
      height: 0,
    },
  },
  fullwidthInput: {
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    '& img': {
      maxHeight: 50,
      maxWidth: 200,
    },
    '& .MuiFormHelperText-root': {
      marginTop: 0,
      height: 0,
    },
  },
  imageUpload: {
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    '& img': {
      maxHeight: 50,
      maxWidth: 200,
    },
    '& .MuiFormHelperText-root': {
      marginTop: 0,
      height: 0,
    },
    '& .MuiFormControl-marginDense': {
      marginBottom: 0,
    },
  },
  inputHalfLeft: {
    display: 'inline-block',
    width: '47%',
    marginLeft: 'auto',
    marginRight: 8,
    '& img': {
      maxHeight: 50,
      maxWidth: 138,
    },
    '& .MuiFormHelperText-root': {
      marginTop: 0,
      height: 0,
    },
  },
  button: {
    fontWeight: 500,
    color: theme.headerFontColor,
    background: theme.themeColor,
    '&:hover': {
      cursor: 'pointer',
      background: theme.themeColorDark,
    },
  },
}))

export const renderSelectField = ({
  input,
  label,
  required = true,
  meta: { touched, error },
  children,
  onChange,
  ...custom
}) => {
  return (
    <FormControl style={{ width: '100%' }} margin="dense" error={touched && error}>
      <InputLabel id="demo-simple-select-label" required={required}>
        {label}
      </InputLabel>
      <Select
        {...input}
        MenuProps={{ disableScrollLock: true }}
        onChange={(event) => {
          onChange(event.target.value, input)
        }}
        children={children}
        {...custom}
      />
      <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
  )
}

const renderCheckbox = ({ input, label, meta: { touched, error }, children, checked, ...custom }, onChange) => {
  return (
    <FormControlLabel
      id={'CheckboxLabel' + input.name}
      control={
        <Checkbox
          id={'Checkbox' + input.name}
          name={input.name}
          checked={checked}
          color="primary"
          onChange={onChange}
        />
      }
      label={label}
      // @ts-ignore
      error={touched && error}
    />
  )
}

const ExhibitActivationCode = (props) => {
  const form = useForm()
  const translate = useTranslate()

  return (
    <CustomField
      initialValue={props.initialValue}
      name="exhibit_activation_code"
      component={renderInput}
      label={translate('Exhibit Activation Code')}
      fullWidth={true}
      onChange={(event) => {
        const value = event.target.value
        form.change('exhibit_activation_code', value)
        if (props.onChange) {
          props.onChange(event)
        }
      }}
    />
  )
}

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

const FormWindowReference = (props) => {
  const form = useForm()
  window.registerForm = form
  return null
}

const isEmptyObject = (obj) => {
  return !obj || Object.keys(obj).length === 0
}

type RegisterFormPropsType = {
  initialValues?: any
  onSuccess?(): void
  Logo: React.ReactNode
  onExistingAccountLogin(): void
}

const RegisterForm = ({ initialValues, onSuccess, Logo, onExistingAccountLogin }: RegisterFormPropsType) => {
  const [show_all_countries, setAllCountries] = useState(false)
  const [exhibitorActivationCodeToCheck, setExhibitorActivationCodeToCheck] = useState(null)
  const debouncedExhibitorActivationCodeToCheck = useDebounce(exhibitorActivationCodeToCheck, 500)
  const [exhibitorActivationCodeOrg, setExhibitorActivationCodeOrg] = useState<any>()
  const [exhibitDataShareOrgId, setExhibitDataShareOrgId] = useState(null)
  const [defaultGDPRAnswer, setDefaultGDPRAnswer] = useState(false)
  const [showGDPRConsent, setShowGDPRConsent] = useState(false)
  const [prefixFieldEnabled, setPrefixFieldEnabled] = useState(true)
  const [keepCountrySelectOpen, setKeepCountrySelectOpen] = useState(false)

  const translate = useTranslate()
  const location = useLocation()
  const dispatch = useDispatch()
  const isLoading = useSelector((state: any) => Boolean(state.admin && state.admin.loading))
  //@ts-ignore
  const params = isEmptyObject(location.params) ? getQueryParams() : location.params
  const isLiteStr = loadQueryVariable('is_lite') || params.is_lite
  const isLite = isLiteStr !== undefined && isLiteStr !== '0' && isLiteStr !== 'false'
  const classes = useStyles()
  const [checkBoxOSLite, setCheckBoxOSLite] = useState(true)

  const handleSubmitForm = ({
    company_name,
    email,
    password,
    country_iso2,
    phone,
    country_calling_id = '',
    exhibit_activation_code = '',
    gdpr_email_consent,
    color_highlight,
    logo,
    projects_sold_per_month,
    service_offering,
    zip,
  }) => {
    const { state = '' } = location

    const user_data = {
      is_lite: checkBoxOSLite && isLite,
    }

    const phoneWithCode =
      country_calling_id && phone ? `+(${countryMapping[country_calling_id].prefix})${phone}` : phone

    dispatch(
      userRegister({
        payload: {
          company_name,
          email,
          password,
          country_iso2,
          exhibit_activation_code,
          phone: phoneWithCode,
          exhibitDataShareOrgId,
          logo,
          color_highlight,
          email_marketing_opt_in: showGDPRConsent ? gdpr_email_consent : true,
          projects_sold_per_month,
          zip,
          user_data,
          service_offering,
        },
        callbacks: {
          onSuccess,
        },
      })
    )
  }

  const checkExhibitActivationCode = (value) => {
    // On first load when supplying code form URL, the form value is equal to null
    // so when value is null we will use params.exhibit_activation_code from URL instead
    //
    // Do not use params.exhibit_activation_code when value is empty string since this is required to clear the code
    if (!value && value !== '') {
      value = params.exhibit_activation_code
    }

    if (value) {
      // @ts-ignore
      window.$.ajax({
        url: window.API_BASE_URL + 'check_exhibit_activation_code/?exhibit_activation_code=' + value,
        dataType: 'json',
        success: function (data) {
          if (data && data.org && data.org) {
            setExhibitorActivationCodeOrg(data.org)
          } else {
            setExhibitorActivationCodeOrg(null)
          }
        },
        error: function (response) {
          setExhibitorActivationCodeOrg(null)
        },
      })
    } else {
      setExhibitorActivationCodeOrg(null)
    }
  }

  //don't show for US, show for everyone else. EU and KR defaults to false, everyone else defaults to true
  const checkGDPR = (chosenCountry, form) => {
    const valueBeforeChange = defaultGDPRAnswer
    if (chosenCountry === 'US') {
      if (showGDPRConsent) setShowGDPRConsent(false)
    } else if (!!chosenCountry) {
      setShowGDPRConsent(true)
      if (countriesWhichRequireTermsOptIn.includes(chosenCountry)) {
        setDefaultGDPRAnswer(false)
        // if they changed to an EU country force the toggle to go back to false
        if (valueBeforeChange) form.change('gdpr_email_consent', false)
      } else {
        if (!valueBeforeChange) form.change('gdpr_email_consent', true)
        setDefaultGDPRAnswer(true)
      }
    }
  }

  useEffect(() => {
    checkExhibitActivationCode(debouncedExhibitorActivationCodeToCheck)
  }, [debouncedExhibitorActivationCodeToCheck])

  const { countriesMinimal, countriesAll, countriesWithPrefix, otherCountry } = useCountryListsTranslated()

  const countryList = show_all_countries === true ? [...countriesAll, otherCountry] : countriesMinimal

  return (
    <div className={classes.wrapper}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={4}>
              <div className={classes.logoWrapper}>
                <div style={{ width: '100%' }}>
                  {Logo}
                  <div style={{ textAlign: 'center' }}>
                    <span>{translate('Already have an account?')}</span>{' '}
                    <a
                      id="use-existing-org"
                      href="#"
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className={classes.link}
                      onClick={(e) => {
                        e.preventDefault()
                        onExistingAccountLogin()
                      }}
                    >
                      {translate('Sign in')}
                    </a>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <div className={classes.input}>
                      <CustomField
                        id="company_name"
                        name="company_name"
                        validate={required}
                        component={renderInput}
                        label={translate('Company Name')}
                        required={true}
                        fullWidth={true}
                      />
                    </div>
                    <div className={classes.input}>
                      {prefixFieldEnabled && (
                        <div className={classes.inputHalfLeft}>
                          <CustomField
                            name="country_calling_id"
                            component={renderSelectField}
                            onChange={(value, input) => {
                              input.onChange(value)
                            }}
                            required={false}
                            label={translate('Country Code')}
                            renderValue={(v) => `${countryMapping[v].flag} +${countryMapping[v].prefix}`}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {countriesWithPrefix.map((c) => {
                              return (
                                <MenuItem id={'country_calling_id_' + c.iso2} value={c.iso2} key={c.iso2}>
                                  {`${c.flag} ${c.translatedName} +${c.prefix}`}
                                </MenuItem>
                              )
                            })}
                          </CustomField>
                        </div>
                      )}
                      <div className={prefixFieldEnabled ? classes.inputHalfLeft : classes.input}>
                        <CustomField
                          name="phone"
                          component={renderInput}
                          label={translate('resources.roles.fields.phone')}
                          fullWidth={true}
                        />
                      </div>
                    </div>
                    <div className={classes.input}>
                      <CustomField
                        name="email"
                        type="email"
                        component={renderInput}
                        required={true}
                        validate={composeValidators(required, emailFormat)}
                        label={translate('Email')}
                        fullWidth={true}
                      />
                    </div>

                    {isLite && (
                      <div>
                        <FormControlLabel
                          control={
                            <Switch
                              id="checkOSLite"
                              checked={checkBoxOSLite}
                              onChange={(event) => setCheckBoxOSLite(event.target.checked)}
                            />
                          }
                          label={translate('Use OpenSolar Lite')}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <div className={classes.input}>
                      <CustomField
                        name="country_iso2"
                        validate={required}
                        defaultValue={params.country_iso2}
                        component={renderSelectField}
                        onChange={(value, input) => {
                          if (!!countryMapping[value]?.prefix) {
                            form.change('country_calling_id', value)
                            setPrefixFieldEnabled(true)
                          } else {
                            form.change('country_calling_id', '')
                            setPrefixFieldEnabled(false)
                          }
                          if (value !== 'change_view') {
                            input.onChange(value)
                            setKeepCountrySelectOpen(false)
                            checkGDPR(value, form)
                          }
                        }}
                        onClick={(e) => {
                          setKeepCountrySelectOpen(!keepCountrySelectOpen)
                          if (e.target.value === 'change_view') {
                            setAllCountries(!show_all_countries)
                            form.change('country_iso2', '')
                            setKeepCountrySelectOpen(true)
                          } else {
                            setKeepCountrySelectOpen(!keepCountrySelectOpen)
                          }
                        }}
                        label={translate('Country')}
                        open={keepCountrySelectOpen}
                      >
                        <MenuItem value={''} key={0}></MenuItem>
                        {countryList.map((c) => (
                          <MenuItem id={'Country' + c.iso2} value={c.iso2} key={c.iso2}>
                            {c.translatedName}
                          </MenuItem>
                        ))}
                        <MenuItem value={'change_view'} id={'change_view'}>
                          {show_all_countries ? translate('Show less...') : translate('Show more...')}
                        </MenuItem>
                      </CustomField>
                    </div>
                    <div className={classes.input}>
                      <CustomField
                        name="zip"
                        component={renderInput}
                        label={translate('resources.orgs.fields.zip')}
                        fullWidth={true}
                      />
                    </div>
                    <div className={classes.input}>
                      <PasswordInput scenario="register" options={{ required: true }} />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.fullwidthInput}>
                      <Field
                        name="service_offering"
                        required
                        validate={required}
                        render={({ meta: { touched, error } }) => {
                          return (
                            <FormControl style={{ width: '100%' }} required margin="dense" error={touched && error}>
                              <InputLabel id="demo-simple-select-label">Service Offering</InputLabel>
                              <CheckboxMultiSelectInput
                                id="demo-simple-select-label"
                                source={'service_offering'}
                                choices={SERVICE_OFFERING_OPTIONS}
                                style={{ width: '100%' }}
                                color={'secondary'}
                              />
                              <FormHelperText>{touched && error}</FormHelperText>
                            </FormControl>
                          )
                        }}
                      />
                    </div>
                    <div className={classes.fullwidthInput}>
                      <CustomField
                        name="projects_sold_per_month"
                        validate={requiredOrZero}
                        defaultValue={params.projects_sold_per_month}
                        component={renderSelectField}
                        onChange={(value, input) => {
                          input.onChange(value)
                        }}
                        label={translate('Projects sold per month')}
                      >
                        {[
                          {
                            name: '0',
                            value: 0,
                          },
                          {
                            name: '1-5',
                            value: 1,
                          },
                          {
                            name: '6-10',
                            value: 6,
                          },
                          {
                            name: '11-20',
                            value: 11,
                          },
                          {
                            name: '21-50',
                            value: 21,
                          },
                          {
                            name: '51-100',
                            value: 51,
                          },
                          {
                            name: '100+',
                            value: 101,
                          },
                        ].map((choice) => (
                          <MenuItem
                            id={'projects_sold_per_month' + choice.value}
                            value={choice.value}
                            key={choice.value}
                          >
                            {translate(choice.name)}
                          </MenuItem>
                        ))}
                      </CustomField>
                    </div>
                    <div style={{ position: 'relative', zIndex: 91 }} className={classes.fullwidthInput}>
                      <CustomField
                        component={ColorInput}
                        label="Company Color (Optional)"
                        validate={validateHexColor}
                        name="color_highlight"
                        source="color_highlight"
                      />
                      <FormDataConsumer>
                        {({ formData, ...rest }) => <ShowHighLightColor value={formData.color_highlight} />}
                      </FormDataConsumer>
                    </div>
                    <div style={{ position: 'relative', zIndex: 90, marginBottom: 0 }} className={classes.imageUpload}>
                      <ImageInput
                        source="logo"
                        label="Company Logo (Optional)"
                        accept="image/*"
                        placeholder={
                          <div>
                            <CloudUploadIcon
                              style={{ verticalAlign: 'middle', marginRight: 5, fill: 'rgba(0,0,0,0.54)' }}
                            />
                            <span style={{ verticalAlign: 'middle', fontSize: 12, color: 'rgba(0,0,0,0.54)' }}>
                              {translate('Drag & drop an image file here')}
                            </span>
                          </div>
                        }
                      >
                        <ImageField source="src" title="title" />
                      </ImageInput>
                    </div>
                    <ImageFieldClearable
                      label="resources.orgs.fields.logo_file_contents"
                      source="logo"
                      maxWidth={578}
                    />
                  </Grid>
                </Grid>
                {(params.exhibit_activation_code ||
                  (exhibitorActivationCodeOrg && exhibitorActivationCodeOrg.data_sharing_agreement_url)) && (
                  <div className={classes.extraWrapper}>
                    {params.exhibit_activation_code && (
                      <ExhibitActivationCode
                        initialValue={params.exhibit_activation_code}
                        onChange={(event) => {
                          setExhibitorActivationCodeToCheck(event.target.value)
                        }}
                      />
                    )}
                    {exhibitorActivationCodeOrg && exhibitorActivationCodeOrg.data_sharing_agreement_url && (
                      <div className={classes.extraCheckboxRow}>
                        <CustomField
                          id="CheckboxShareData"
                          name="CheckboxShareData"
                          label={null}
                          component={(props) => {
                            const onChange = (value, input) => {
                              if (exhibitDataShareOrgId) {
                                setExhibitDataShareOrgId(null)
                              } else {
                                setExhibitDataShareOrgId(exhibitorActivationCodeOrg?.id)
                              }
                            }
                            return renderCheckbox(props, onChange)
                          }}
                          labelPosition="right"
                          checked={exhibitDataShareOrgId}
                        />
                        <span>
                          {'Authorize OpenSolar to share certain data with ' +
                            exhibitorActivationCodeOrg?.name +
                            ' as described in the '}
                          <a
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            target="_blank"
                            href={exhibitorActivationCodeOrg.data_sharing_agreement_url}
                            rel="noreferrer"
                          >
                            Data Sharing Authorization Document
                          </a>
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {showGDPRConsent && (
                  <div className={classes.extraCheckboxRow}>
                    <CustomField
                      component={CheckboxInput}
                      defaultValue={defaultGDPRAnswer}
                      elStyle={{ maxWidth: '100%' }}
                      name={'gdpr_email_consent'}
                      label={GDPRText}
                    />
                  </div>
                )}
                <p className={classes.terms}>
                  <TermsAndPrivacyLinks />
                </p>
                <Button
                  id="create_account_btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    pristine ||
                    isLoading ||
                    (exhibitorActivationCodeToCheck && !exhibitorActivationCodeOrg) ||
                    (exhibitorActivationCodeOrg &&
                      exhibitorActivationCodeOrg.data_sharing_agreement_url &&
                      exhibitorActivationCodeOrg.data_sharing_agreement_url.length > 0 &&
                      !exhibitDataShareOrgId)
                  }
                  fullWidth
                  loading={isLoading}
                >
                  <span>{translate('Create account')}</span>
                </Button>
                <FormWindowReference />
              </form>
              <p style={{ textAlign: 'center', fontSize: '11pt', margin: '10px 0px 0px' }}>
                {translate('OpenSolar is free of charge')}
              </p>
            </Grid>
          </Grid>
        )}
      />
    </div>
  )
}

export default RegisterForm
