import { logAmplitudeEvent } from 'amplitude/amplitude'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { projectMilestonesSelectors } from 'ducks/projectMilestones'
import { RefObject, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FinanceCtaType } from 'resources/financeCTAs/constants'
import { ALL_FINCOS } from 'resources/integrations/constants'
import restClient from 'restClient'
import { StudioSystemType } from 'types/global'
import { ConnectedOrgBrief, OrgType } from 'types/orgs'
import { PaymentFrequencyTypeEnum, PaymentOptionDataType } from 'types/paymentOptions'
import { parseJsonSafe, periodsPerYear } from 'util/misc'
import { CalculatedPaymentOption } from './paymentOptionSelection/types'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const convertPmtDataToCalculatedPaymentOption = (paymentOptionData: PaymentOptionDataType) => {
  if (!paymentOptionData) return undefined
  const toReturn: CalculatedPaymentOption = {
    title: paymentOptionData.title,
    integration: paymentOptionData.integration,
    payment_frequency: paymentOptionData.payment_frequency,
    payment_type: paymentOptionData.payment_type,
    interest_rate: paymentOptionData.interest_rate,
    interest_rate_disclaimer: null,
    system_price: paymentOptionData?.top_line_system_price,
    term_years: paymentOptionData.term_years,
    term_periods: paymentOptionData.term,
    payment_option_id: paymentOptionData.id,
    repayment: convertRepaymentToMonthly(paymentOptionData.regular_payment, paymentOptionData.payment_frequency),
    messages: [],
    is_popular: false,
  }
  return toReturn
}

export const useIsOnScreen = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [ref])

  useEffect(() => {
    // @ts-ignore
    if (ref) observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return isIntersecting
}

export const useGetOrgIdFromPaymentOptions = (
  systemUuid: string | null | undefined,
  project_org_id: number,
  targetPmtId?: number
) => {
  const [orgId, setOrgId] = useState<number | undefined>(undefined)

  useEffect(() => {
    let system = window.editor.getSystems()?.find((sys) => sys.uuid === systemUuid)
    if (system?.payment_options?.length) {
      const paymentOption = targetPmtId ? system.payment_options?.find((pmt) => pmt.id === targetPmtId) : undefined
      if (paymentOption) setOrgId(paymentOption.org_id)
      else setOrgId(system.payment_options[0].org_id)
    } else {
      setOrgId(project_org_id)
    }
  }, [systemUuid])

  return orgId
}

/**
 * If a project is using payment options from an org that the currently logged in Role does not belong to, that ConnectedOrgBrief is returned. Otherwise undefined is returned
 * @param  {[OrgType | undefined]} thisRolesOrg The Org of the currently logged in role
 * @param  {[number | undefined]} orgIdFromUsedPaymentOptions The id of the org whose payment options are being used on a project
 * @return {[ConnectedOrgBrief | undefined]} Only returns a ConnectedOrgBrief if the org that is being used is not this role's org
 */
export const useGetConnectedOrgBeingUsed = (
  thisRolesOrg: OrgType | undefined,
  orgIdFromUsedPaymentOptions: number | undefined
) => {
  const [connectedOrgBeingUsed, setConnectedOrgBeingUsed] = useState<ConnectedOrgBrief | undefined>(undefined)

  // check to see if the payment options on this system belong to a different org. If so, store that org in state so we can updat the UI
  useEffect(() => {
    if (thisRolesOrg && orgIdFromUsedPaymentOptions && thisRolesOrg?.id !== orgIdFromUsedPaymentOptions) {
      const connectedOrg = thisRolesOrg?.connected_orgs?.find(
        (connected) => connected.org_id === orgIdFromUsedPaymentOptions
      )
      if (connectedOrg) {
        setConnectedOrgBeingUsed(connectedOrg)
      } else {
        setConnectedOrgBeingUsed(undefined)
      }
    }
  }, [orgIdFromUsedPaymentOptions, thisRolesOrg])
  return connectedOrgBeingUsed
}

type SimpleOrgData = {
  name: string | undefined
  id: number | undefined
}

/**
 * Returns a list of simple org data for any connected org that has shared at least one payment option from the specified integration with the org that is currently logged in
 * @param  {[string]} integration The integration whose payment options we are interested in
 * @param  {[ConnectedOrgBrief[] | undefined]} connectedOrgs All connected_orgs that the currently logged in org has access to
 * * @param  {[OrgType | undefined]} thisOrg The org that is currently logged in
 * @return {[SimpleOrgData[]]} Returns an array of SimpleOrgData where each element is a connected org that has shared the target integration with the currently logged in org
 */
export const useGetConnectedOrgsThatSharedIntegration = (
  integration: string,
  connectedOrgs: ConnectedOrgBrief[] | undefined,
  thisOrg: OrgType | undefined
) => {
  const [orgsThatSharedIntegration, setOrgsThatSharedIntegration] = useState<SimpleOrgData[]>([])
  const [hasQueriedPmts, setHasQueriedPmts] = useState<boolean>(false)

  useEffect(() => {
    if (connectedOrgs?.length && !hasQueriedPmts) {
      let sharedWithOrgIds: number[] = connectedOrgs?.map((connected) => connected.org_id)
      if (thisOrg?.id) sharedWithOrgIds.push(thisOrg?.id)
      setHasQueriedPmts(true)
      restClientInstance('CUSTOM_GET', 'CUSTOM', {
        url: `payment_options/?fieldset=list&limit=10000&ordering=-id&visible_to=${thisOrg?.id}&owned_by=${sharedWithOrgIds}&show_archived=1`,
      })
        .then((res) => {
          const pmts = res.data
          const integrationPmts: PaymentOptionDataType[] = pmts.filter((pmt) => {
            return parseJsonSafe(pmt.configuration_json)?.integration === integration
          })
          let orgIdsSharingIntegration: number[] = []
          let orgsSharingIntegration: SimpleOrgData[] = []
          integrationPmts?.forEach((pmt) => {
            if (pmt?.org_id && !orgIdsSharingIntegration.includes(pmt?.org_id)) {
              orgIdsSharingIntegration.push(pmt?.org_id)
              let connectedOrg = connectedOrgs?.find((connected) => connected.org_id === pmt?.org_id)
              if (connectedOrg) {
                orgsSharingIntegration.push({
                  name: connectedOrg?.org_name,
                  id: connectedOrg?.org_id,
                })
              }
            }
          })
          setOrgsThatSharedIntegration(orgsSharingIntegration)
        })
        .catch((err) => {
          console.warn('Unable to fetch payment options', err)
        })
    }
  }, [orgsThatSharedIntegration, hasQueriedPmts])

  return orgsThatSharedIntegration
}

export const useLogCalculationMismatches = (
  isCalculating: boolean,
  pmtsFromDialog: CalculatedPaymentOption[],
  calculatedPmts: PaymentOptionDataType[],
  projectId: number,
  system: StudioSystemType
) => {
  const [dialogPmtIdToRepayment, setDialogPmtIdToRepayment] = useState<CalculatedPaymentOption[]>([])
  const [waitingForCalcsToStop, setWaitingForCalcsToFinish] = useState<boolean>(false)

  const availablePaymentOptions = useSelector(paymentOptionSelectionSelectors.getAvailablePaymentOptions)

  // Step 1: save the payment options that came from the dialog in state
  useEffect(() => {
    if (pmtsFromDialog?.length) {
      let dialogPmtsToCompare: CalculatedPaymentOption[] = []
      let ignoreDueToAutoDiscount = false
      pmtsFromDialog?.forEach((dialogPmt) => {
        if (dialogPmt.payment_type !== 'cash' && !dialogPmt.messages?.length) {
          let pmtWithConfigurationData = availablePaymentOptions?.find((pmt) => pmt.id === dialogPmt.payment_option_id)
          if (!pmtWithConfigurationData) return

          // the dialog does not support pricing for auto_discount = false products, so we shouldn't track those mismatches UNLESS the force_apply_dealer_fee_discounts field is true
          if (pmtWithConfigurationData?.auto_discount === false && !system?.pricing?.force_apply_dealer_fee_discounts) {
            ignoreDueToAutoDiscount = true
          }
          dialogPmtsToCompare.push(dialogPmt)
        }
      })
      if (!ignoreDueToAutoDiscount) setDialogPmtIdToRepayment(dialogPmtsToCompare)
    }
  }, [pmtsFromDialog])

  // Step 2 - once calcs start, set waitingForCalcsToStop to true so that when calcs stop we know it's time to assess
  useEffect(() => {
    if (isCalculating && dialogPmtIdToRepayment && !waitingForCalcsToStop) {
      setWaitingForCalcsToFinish(true)
    }
  }, [isCalculating, dialogPmtIdToRepayment, waitingForCalcsToStop])

  // step 3 - compare repayment calcs, log event and reset
  useEffect(() => {
    if (waitingForCalcsToStop && !isCalculating && dialogPmtIdToRepayment && calculatedPmts?.length) {
      let bestBucket = 0
      let goodBucket = 0
      let okBucket = 0
      let badBucket = 0
      let veryBadBucket = 0
      let totalAssessed = 0
      dialogPmtIdToRepayment?.forEach((dialogPmt) => {
        let calculatedPmt = calculatedPmts?.find((calcPmt) => calcPmt.id === dialogPmt.payment_option_id)

        // the dialog does not support down payment overrides, don't log payment options with one
        if (calculatedPmt && !calculatedPmt?.down_payment_override) {
          let calculatedRepayment = calculatedPmt.regular_payment
          let dialogRepayment = dialogPmt.repayment || 0
          let difference = Math.abs(calculatedRepayment - dialogRepayment) / calculatedRepayment

          totalAssessed += 1
          if (difference <= 0.0001) {
            bestBucket += 1
          } else if (difference <= 0.005) {
            goodBucket += 1
          } else if (difference <= 0.01) {
            okBucket += 1
          } else if (difference <= 0.025) {
            badBucket += 1
          } else {
            veryBadBucket += 1
            logAmplitudeEvent('payments_page_calcuation_mismatch', {
              project_id: projectId,
              differecePct: difference,
              integration: calculatedPmt.integration,
              payment_option_id: calculatedPmt.id,
              dialogRepayment: dialogRepayment,
              calculatedRepayment: calculatedRepayment,
            })
          }
        }
      })

      if (totalAssessed) {
        // This event was not firing without a timeout. It's not clear to me why, but this is an important enough thiing to track (especially early on in Elevate Finance's life)
        // that I think it's worth the grossness to just wrap this in a timeout and log the data
        setTimeout(() => {
          logAmplitudeEvent('payments_page_calculation_result', {
            total: totalAssessed,
            bestBucket,
            goodBucket,
            okBucket,
            badBucket,
            veryBadBucket,
            project_id: projectId,
          })
        }, 2000)
      }

      // clear out state
      setDialogPmtIdToRepayment([])
      setWaitingForCalcsToFinish(false)
    }
  }, [calculatedPmts])
}

export const convertRepaymentToMonthly = (
  unadjustedRepayment: number,
  paymentFrequency: PaymentFrequencyTypeEnum | undefined
) => {
  if (!unadjustedRepayment || !paymentFrequency || paymentFrequency === PaymentFrequencyTypeEnum.monthly) {
    return unadjustedRepayment
  }
  if (!periodsPerYear[paymentFrequency]) return unadjustedRepayment
  let paymentsPerYear = periodsPerYear[paymentFrequency]
  return (unadjustedRepayment * paymentsPerYear) / 12
}

export const convertMonthlyPaymentBackToPeriod = (
  monthlyPayment: number | null | undefined,
  paymentFrequency: PaymentFrequencyTypeEnum | undefined
) => {
  if (!monthlyPayment || !paymentFrequency || paymentFrequency === PaymentFrequencyTypeEnum.monthly) {
    return monthlyPayment
  }
  if (!periodsPerYear[paymentFrequency]) return monthlyPayment
  let paymentsPerYear = periodsPerYear[paymentFrequency]
  return (monthlyPayment * 12) / paymentsPerYear
}

export const convertPaymentToFrequency = (
  payment: number | null | undefined,
  currentFrequency: PaymentFrequencyTypeEnum | undefined,
  convertToFrequency: PaymentFrequencyTypeEnum | undefined
) => {
  if (
    payment &&
    currentFrequency &&
    convertToFrequency &&
    periodsPerYear[currentFrequency] &&
    periodsPerYear[convertToFrequency]
  ) {
    return (payment * periodsPerYear[currentFrequency]) / periodsPerYear[convertToFrequency]
  } else {
    return payment
  }
}

/**
 * Uses the system uuid and a payment option id to first find the system from window.editor.getSystems() and then find the payment option from that system
 */
export const useGetPaymentOptionDataFromSystem = (systemUuid: string | null, pmtId: number) => {
  const [paymentOption, setPaymentOption] = useState<undefined | PaymentOptionDataType>(undefined)

  const refreshMilestoneTrigger = useSelector(projectMilestonesSelectors.getRefreshMilestonesTrigger)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  useEffect(() => {
    if (pmtId && systemUuid) {
      const system = window.editor.getSystems()?.find((sys) => sys.uuid === systemUuid)
      if (system && system?.payment_options?.length) {
        let foundPmt = system.payment_options?.find((pmt) => pmt.id === pmtId)
        setPaymentOption(foundPmt)
      } else {
        setPaymentOption(undefined)
      }
    }
  }, [pmtId, systemUuid, refreshMilestoneTrigger, refreshSystemsTrigger])

  return paymentOption
}

/**
 * Checks the integration and the finance_ctas on the payment option (if there are any) to determine whether the pro-facing application button should be hidden
 */
export const useGetShouldHideProApplication = (
  pmt: PaymentOptionDataType | undefined,
  integration: string | undefined | null
) => {
  const [shouldHideProCTA, setShouldHideProCTA] = useState<boolean>(true)

  useEffect(() => {
    if (!pmt && !integration) {
      setShouldHideProCTA(true)
    } else {
      // phenix is a lgacy integration so we can't yet do this via finance_ctas. But we should never show pros the application CTA for this integration
      if (integration === 'phoenix') {
        setShouldHideProCTA(true)
      } else if (pmt?.finance_ctas?.length) {
        // If the payment option has finance_ctas check to see if they are pro-facing applications
        let shouldHide = true
        pmt.finance_ctas.forEach((cta) => {
          if (
            cta.is_pro_facing &&
            [
              FinanceCtaType.FETCH_IFRAME_URL,
              FinanceCtaType.FORM_REDIRECT,
              FinanceCtaType.SUBMIT_CREDIT_APPLICATION_HOSTED,
              FinanceCtaType.SUBMIT_CREDIT_APPLICATION_IFRAME,
            ].includes(cta.type)
          ) {
            shouldHide = false
          }
        })
        // if the payment option has finance_ctas but none are pro-facing applications then we should hide the CTA
        setShouldHideProCTA(shouldHide)
      }
      // if there are no finance_ctas and it's a legacy integration then we should show the CTA
      else if (integration && ALL_FINCOS.includes(integration)) {
        setShouldHideProCTA(false)
      } else {
        setShouldHideProCTA(true)
      }
    }
  }, [pmt, integration])

  return shouldHideProCTA
}

export const doRecalc = (systemUuid: string) => {
  window.editor.filter('type', 'OsSystem').forEach((system: any) => {
    if (system.uuid === systemUuid) {
      system.output.hashed_args = undefined //clear hashed args to bypass calc cache
      system.payment_options.forEach((pmt) => {
        pmt.hashed_args = null
      })
      window.Designer.requestSystemCalculations(system)
    }
  })
}
