import { Dialog, DialogTitle, IconButton, makeStyles } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button } from 'opensolar-ui'
import { InvoicePaymentDetails } from 'pages/cashFlow/invoices/types'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { makeNextPaymentRequest, previewEmail } from 'pages/cashFlow/utils'
import { useNotify, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SendEmailDialogWrapper from './SendEmailDialogWrapper'

type PropTypes = {
  paymentRequest: PaymentRequestType | InvoicePaymentDetails
  blueBtn?: boolean | false
  label: string
  actionType: 'invoice' | 'reminder'
  projectId: number
  systemUuid: string
  disableButtons?: boolean
  setDisableButtons?: (val: boolean) => void
  setActionsAreShown?: (val: boolean) => void
  buttonSize?: 'small' | 'medium' | 'large'
  buttonColor?: 'primary' | 'secondary' | 'default'
  buttonVariant?: 'text' | 'outlined' | 'contained'
  startIcon?: JSX.Element | null
  fullWidth?: boolean
}

const useStyles = makeStyles<OpenSolarThemeType, { blueBtn: boolean }>((theme) => ({
  titleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.greyLight1,
  },
  content: {
    lineHeight: 1.5,
  },
  closeButton: {
    color: theme.greyMid1,
  },

  templateContent: {
    paddingTop: 10,
  },
  field: {
    margin: 0,
  },

  loading: {
    padding: '4rem',
  },

  btn: {
    backgroundColor: ({ blueBtn }) => (blueBtn ? '#4272DD !important' : ''),
    color: ({ blueBtn }) => (blueBtn ? 'white !important' : 'black'),
    '&:hover': {
      backgroundColor: ({ blueBtn }) => (blueBtn ? '#2B5DCA !important' : ''),
      boxShadow: ({ blueBtn }) =>
        blueBtn
          ? '0px 2px 4px -1px rgba(0, 0, 0, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12) !important'
          : '',
    },
  },
}))

const SendInvoiceButton: React.FC<PropTypes> = (props) => {
  const orgLoggedIn = useSelector(orgSelectors.getOrg)
  const orgId = props.paymentRequest?.org || orgLoggedIn?.id

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)
  const [subjectTemplate, setSubjectTemplate] = useState<string>('')
  const [bodyTemplate, setBodyTemplate] = useState<string>('')
  const [invoiceId, setInvoiceId] = useState<number>()

  const classes = useStyles({ blueBtn: props.blueBtn || false })
  const translate = useTranslate()
  const notify = useNotify()

  const closeDialog = () => {
    setDialogIsOpen(false)
  }

  const previewEmailContent = () => {
    setIsLoading(true)
    setDialogIsOpen(true)

    if (props.actionType === 'reminder') {
      if (orgId && props.paymentRequest.invoice) {
        previewEmail(orgId, props.paymentRequest.invoice, 'invoice_reminder')
          .then((res) => {
            setInvoiceId(props.paymentRequest.invoice)
            setSubjectTemplate(res?.subject)
            setBodyTemplate(res?.body)
            logAmplitudeEvent('cashflow_invoice_reminder_sent', {
              source: 'invoices table',
              org_id: props.paymentRequest?.org,
              project_id: props.paymentRequest?.project,
              payment_request_id: props.paymentRequest?.id,
              payment_number: props.paymentRequest?.payment_number,
            })
          })
          .finally(() => setIsLoading(false))
      }
    } else {
      makeNextPaymentRequest(
        props.paymentRequest?.org,
        props.projectId,
        props.systemUuid,
        props.paymentRequest.payment_option
      )
        .then((data) => {
          if (orgId && data.invoice_id) {
            setInvoiceId(data.invoice_id)
            previewEmail(orgId, data.invoice_id, 'issue_invoice')
              .then((res) => {
                setSubjectTemplate(res?.subject)
                setBodyTemplate(res?.body)
              })
              .finally(() => {
                setIsLoading(false)
              })
          }
        })
        .catch((err) => {
          notify(err, 'warning', { autoHideDuration: undefined })
          setDialogIsOpen(false)
        })
    }
  }

  return (
    <>
      <Button
        disabled={props.disableButtons}
        size={props.buttonSize || 'small'}
        onClick={previewEmailContent}
        className={classes.btn}
        color={props.buttonColor}
        fullWidth={Boolean(props.fullWidth)}
        variant={props.buttonVariant}
      >
        {props.startIcon ? props.startIcon : <></>}
        {translate('Send %{label}', { label: props.label })}
      </Button>

      {dialogIsOpen && (
        <Dialog open={true} maxWidth="md">
          {isLoading ? (
            <div className={classes.loading}>
              <LoadingDots />
            </div>
          ) : (
            <>
              <DialogTitle>
                <div className={classes.titleWrapper}>
                  <div>{translate('Send %{label} email', { label: props.actionType })}</div>
                  <div>
                    <IconButton onClick={closeDialog}>
                      <CloseOutlined className={classes.closeButton} />
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <SendEmailDialogWrapper
                label={props.actionType}
                invoiceId={invoiceId}
                subjectTemplate={subjectTemplate}
                bodyTemplate={bodyTemplate}
                disableButtons={props.disableButtons}
                setDisableButtons={props.setDisableButtons}
                setActionsAreShown={props.setActionsAreShown}
                setIsLoading={setIsLoading}
                paymentRequest={props.paymentRequest}
              />
            </>
          )}
        </Dialog>
      )}
    </>
  )
}

export default SendInvoiceButton
