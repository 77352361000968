import { makeStyles } from '@material-ui/core/styles'
import { PlugIcon } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import { useFeatureFlag } from 'util/split'
import { getProjectSectionStyles } from '../styles'
import { getProjectCTAs } from './ctas/util'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxHeight: 400,
  },
  sectionTop: {
    padding: 'unset',
  },
  sectionContentWrapper: {
    padding: 20,
    paddingLeft: 0,
    width: '100%',
  },
  header: {
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    padding: 20,
    paddingLeft: 0,
  },
  content: { flex: 1, overflowY: 'auto' },
}))

const IntegrationsSection: React.FC = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const enablePvf = useFeatureFlag('pvf_integration', 'on')
  const enableDownloadBOM = useFeatureFlag('hardware_manage_downloadBOM', 'on')
  const project = useFormState().values as ProjectType
  const ctaList = useMemo(() => {
    let featureFlagsEnabled: string[] = []
    if (enablePvf) featureFlagsEnabled.push('pvf_integration')
    if (enableDownloadBOM) featureFlagsEnabled.push('hardware_manage_downloadBOM')
    return getProjectCTAs(project, featureFlagsEnabled)
  }, [project, enablePvf, enableDownloadBOM])

  if (ctaList.length === 0) return null

  return (
    <div className={`${classes.wrapper} ${sectionStyles.column} ${sectionStyles.moduleHalfWidth}`}>
      <div className={`${sectionStyles.sectionCardTop} ${sectionStyles.row} ${classes.sectionTop}`}>
        <div className={`${sectionStyles.iconPadding}`}>
          <PlugIcon size={18} />
        </div>
        <p className={classes.header}>{translate('Integrations')}</p>
      </div>
      <div className={`${sectionStyles.sectionCardBottom} ${classes.content} ${sectionStyles.column}`}>
        {ctaList.map((item, i) => {
          const Integration = item.component
          return <Integration key={'integration-' + i} project={project} {...item.props} />
        })}
      </div>
    </div>
  )
}

export default IntegrationsSection
