import { DropdownInput } from 'types/mounting'
import { ComptibilityRules } from '../../inputFilters'

interface RowData {
  [key: string]: string
}

export const filterByType = (prods, type) => {
  return prods.filter((p) => p.variableName === type)
}

export const findProd = (prods, prodCode): RowData => {
  const prod = prods.find((p) => p.value === prodCode)
  if (!prod) {
    throw new Error(`Couldn't find product with code ${prodCode}`)
  } else {
    return prod
  }
}

export const getDefaultProd = (allProds, type, color, profile, panel_range, roofType?, orient?) => {
  const prods = filterByType(allProds, type)
  const defaultProduct = prods.find((p) => {
    const incCol = p.color.includes(color)
    const incProf = p.profile.includes(profile)
    const incRange = p.panel_range.includes(panel_range)
    const incRoof = roofType ? p.roof_type.includes(roofType) : true
    const incOrient = orient ? p.match_orientation.includes(orient) : true
    return incCol && incProf && incRange && incRoof && incOrient
  })
  if (defaultProduct) {
    return defaultProduct.value
  } else {
    throw new Error(
      `Couldn't find ${prods[0].dropdown_label} compatible with color${color} ${profile} ${panel_range} ${orient || ''}`
    )
  }
}

export const gmbhProducts = [
  {
    value: '814-0433',
    label: '10/48 End Cap Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '814-0431',
    label: '4/35 End Cap Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '814-0432',
    label: '6/40 End Cap Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '814-0427',
    label: '10/48 End Cap Grey',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '814-0425',
    label: '4/35 End Cap Grey',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'TRUE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '814-0426',
    label: '6/40 End Cap Grey',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '702-0196',
    label: 'End Clamp for panels 30-40mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '30-40',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '702-0197',
    label: 'End Clamp for panels 33-45mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '33-45',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '702-0198',
    label: 'End Clamp for panels 38-50mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '702-0193',
    label: 'End Clamp for panels 30-40mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '30-40',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '702-0194',
    label: 'End Clamp for panels 33-45mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '33-45',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '702-0195',
    label: 'End Clamp for panels 38-50mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '806-0029',
    label: 'Hexagon wood screw 8x80mm 2x for heavy load roof hooks plus 2 washers',
    is_dropdown: 'FALSE',
    dropdown_label: 'Roof Fixing',
    variableName: 'fastener',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '806-0072',
    label: 'Chipboard screw 6x80mm 2xfor flat-tile roof hooks and adjustable roof hooks and standard and plain',
    is_dropdown: 'FALSE',
    dropdown_label: 'Roof Fixing',
    variableName: 'fastener',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '807-0026',
    label: 'Washer M8',
    is_dropdown: 'FALSE',
    dropdown_label: 'Other',
    variableName: 'fastener',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '806-0017',
    label: 'Chipboard screw 6x100mm 3x for slate roof hook',
    is_dropdown: 'FALSE',
    dropdown_label: 'Roof Fixing',
    variableName: 'fastener',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '700-0083',
    label: 'Mid Clamp for panels 30-40mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '30-40',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '700-0084',
    label: 'Mid Clamp for panels 33-45mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '33-45',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '700-0085',
    label: 'Mid Clamp for panels 38-50mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '700-0080',
    label: 'Mid Clamp for panels 30-40mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '30-40',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '700-0081',
    label: 'Mid Clamp for panels 33-45mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '33-45',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '700-0082',
    label: 'Mid Clamp for panels 38-50mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '610-0055',
    label: '1700mm 4/35 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1700',
  },
  {
    value: '610-0097',
    label: '1200mm 4/35 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1200',
  },
  {
    value: '610-0713',
    label: '600mm 4/35 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '600',
  },
  {
    value: '800-0992',
    label: '6000mm 4/35 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '6000',
  },
  {
    value: '610-0714',
    label: '600mm 6/40 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '600',
  },
  {
    value: '800-0025',
    label: '4150mm 10/48 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '4150',
  },
  {
    value: '800-1132',
    label: '6000mm 10/48 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '6000',
  },
  {
    value: '800-1341',
    label: '2400mm 10/48 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '2400',
  },
  {
    value: '800-1413',
    label: '4400mm 10/48 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '4400',
  },
  {
    value: '610-0056',
    label: '1700mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1700',
  },
  {
    value: '610-0096',
    label: '2250mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '2250',
  },
  {
    value: '610-0704',
    label: '1200mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1200',
  },
  {
    value: '610-0705',
    label: '600mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '600',
  },
  {
    value: '800-0020',
    label: '4150mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '4150',
  },
  {
    value: '800-0021',
    label: '3150mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '3150',
  },
  {
    value: '800-1055',
    label: '6000mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '6000',
  },
  {
    value: '800-1317',
    label: '5200mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '5200',
  },
  {
    value: '800-1344',
    label: '2400mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '2400',
  },
  {
    value: '800-1405',
    label: '4400mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '4400',
  },
  {
    value: '800-1384',
    label: '3300mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '3300',
  },
  {
    value: '610-0070',
    label: '2400mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '2400',
  },
  {
    value: '800-0017',
    label: '4150mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '4150',
  },
  {
    value: '800-0019',
    label: '3150mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '3150',
  },
  {
    value: '800-1057',
    label: '6000mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '6000',
  },
  {
    value: '800-1319',
    label: '5200mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '5200',
  },
  {
    value: '800-1411',
    label: '4400mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '4400',
  },
  {
    value: '800-1412',
    label: '3300mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '3300',
  },
  {
    value: '800-1442',
    label: '3600mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'TRUE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '3600',
  },
  {
    value: '602-0050',
    label: '10/48 Rail Connector Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Rail Connector',
    variableName: 'railSplice',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '602-0048',
    label: '4/35 Rail Connector Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Rail Connector',
    variableName: 'railSplice',
    is_default: 'TRUE',
    profile: '4/35',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '602-0049',
    label: '6/40 Rail Connector Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Rail Connector',
    variableName: 'railSplice',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '707-0202',
    label: 'Standard Roof Hook Stainless Steel 180-7-45 – Comfort',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '707-0212',
    label: 'Roof Hook Stainless Steel 30-7-29 Plain Tiles pre-assembled',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    length: '1',
  },
  {
    value: '707-0036',
    label: 'Roof Hook Stainless Steel 30-7-28 Slate Tiles pre-assembled',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0017',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '3',
    length: '1',
  },
  {
    value: '707-0204',
    label: 'Roof Hook Stainless Steel 150-9-49 Heavy Load – Comfort',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0029',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    length: '1',
  },
  {
    value: '707-0201',
    label: 'Roof Hook Stainless Steel 180-7-35 Two-Fold Adjustable – Comfort',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    length: '1',
  },
  {
    value: '805-0047',
    label: 'Roof Hook Stainless Steel 180-7-44 - 90° - Vertical Mounting',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    length: '1',
  },
  {
    value: '707-0050',
    label: 'Roof Hook Aluminum 100-7-40',
    is_dropdown: '',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: '',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    length: '1',
  },
  {
    value: '707-0214',
    label: 'Roof Hook Aluminum 100-9-45',
    is_dropdown: '',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: '',
    profile: '4/35|6/40|10/49',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-51',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    length: '1',
  },
  {
    value: '707-0213',
    label: 'Roof Hook Aluminum Black 100-9-45',
    is_dropdown: '',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: '',
    profile: '4/35|6/40|10/50',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-52',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    length: '1',
  },
  {
    value: '707-0053',
    label: 'Roof Hook Aluminum 111-9-40 Vario',
    is_dropdown: '',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: '',
    profile: '4/35|6/40|10/51',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-53',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    length: '1',
  },
  {
    value: '805-0010',
    label: 'Standard Roof Hook Stainless Steel 180-7-45 ',
    is_dropdown: '',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'TRUE',
    profile: '4/35|6/40|10/52',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-54',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    length: '1',
  },
  {
    value: '805-0051',
    label: 'Roof Hook Stainless Steel 150-9-49 Heavy Load',
    is_dropdown: '',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: '',
    profile: '4/35|6/40|10/53',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-55',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0029',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '805-0071',
    label: 'Roof Hook Stainless Steel 180-7-35 Two-Fold Adjustable',
    is_dropdown: '',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: '',
    profile: '4/35|6/40|10/54',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-56',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '805-0018',
    label: 'Roof Hook Stainless Steel 30-7-29 Plain Tiles',
    is_dropdown: '',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: '',
    profile: '4/35|6/40|10/55',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-57',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '1',
    length: '1',
  },
  {
    value: '805-0007',
    label: 'Roof Hook Stainless Steel 30-7-28 Slate Tiles',
    is_dropdown: '',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: '',
    profile: '4/35|6/40|10/56',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-58',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0017',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '1',
    length: '1',
  },
]

export const gmbhDropdowns: DropdownInput[] = [
  {
    variableName: 'mountingRail',
    label: 'Rail',
    default: '800-1442',
    options: [
      {
        value: '610-0055',
        label: '1700mm 4/35 Rail Black',
      },
      {
        value: '610-0097',
        label: '1200mm 4/35 Rail Black',
      },
      {
        value: '610-0713',
        label: '600mm 4/35 Rail Black',
      },
      {
        value: '800-0992',
        label: '6000mm 4/35 Rail Black',
      },
      {
        value: '610-0714',
        label: '600mm 6/40 Rail Black',
      },
      {
        value: '800-0025',
        label: '4150mm 10/48 Rail Silver',
      },
      {
        value: '800-1132',
        label: '6000mm 10/48 Rail Silver',
      },
      {
        value: '800-1341',
        label: '2400mm 10/48 Rail Silver',
      },
      {
        value: '800-1413',
        label: '4400mm 10/48 Rail Silver',
      },
      {
        value: '610-0056',
        label: '1700mm 4/35 Rail Silver',
      },
      {
        value: '610-0096',
        label: '2250mm 4/35 Rail Silver',
      },
      {
        value: '610-0704',
        label: '1200mm 4/35 Rail Silver',
      },
      {
        value: '610-0705',
        label: '600mm 4/35 Rail Silver',
      },
      {
        value: '800-0020',
        label: '4150mm 4/35 Rail Silver',
      },
      {
        value: '800-0021',
        label: '3150mm 4/35 Rail Silver',
      },
      {
        value: '800-1055',
        label: '6000mm 4/35 Rail Silver',
      },
      {
        value: '800-1317',
        label: '5200mm 4/35 Rail Silver',
      },
      {
        value: '800-1344',
        label: '2400mm 4/35 Rail Silver',
      },
      {
        value: '800-1405',
        label: '4400mm 4/35 Rail Silver',
      },
      {
        value: '800-1384',
        label: '3300mm 4/35 Rail Silver',
      },
      {
        value: '800-1442',
        label: '3600mm 4/35 Rail Silver',
      },
      {
        value: '610-0070',
        label: '2400mm 6/40 Rail Silver',
      },
      {
        value: '800-0017',
        label: '4150mm 6/40 Rail Silver',
      },
      {
        value: '800-0019',
        label: '3150mm 6/40 Rail Silver',
      },
      {
        value: '800-1057',
        label: '6000mm 6/40 Rail Silver',
      },
      {
        value: '800-1319',
        label: '5200mm 6/40 Rail Silver',
      },
      {
        value: '800-1411',
        label: '4400mm 6/40 Rail Silver',
      },
      {
        value: '800-1412',
        label: '3300mm 6/40 Rail Silver',
      },
    ],
  },
  {
    variableName: 'roofHook',
    label: 'Roof Hook',
    default: '805-0010',
    options: [
      {
        value: '707-0202',
        label: 'Standard Roof Hook Stainless Steel 180-7-45 – Comfort',
      },
      {
        value: '707-0212',
        label: 'Roof Hook Stainless Steel 30-7-29 Plain Tiles pre-assembled',
      },
      {
        value: '707-0036',
        label: 'Roof Hook Stainless Steel 30-7-28 Slate Tiles pre-assembled',
      },

      {
        value: '707-0201',
        label: 'Roof Hook Stainless Steel 180-7-35 Two-Fold Adjustable – Comfort',
      },
      {
        value: '805-0047',
        label: 'Roof Hook Stainless Steel 180-7-44 - 90° - Vertical Mounting',
      },
      {
        value: '707-0050',
        label: 'Roof Hook Aluminum 100-7-40',
      },
      {
        value: '707-0214',
        label: 'Roof Hook Aluminum 100-9-45',
      },
      {
        value: '707-0213',
        label: 'Roof Hook Aluminum Black 100-9-45',
      },
      {
        value: '707-0053',
        label: 'Roof Hook Aluminum 111-9-40 Vario',
      },
      {
        value: '805-0010',
        label: 'Standard Roof Hook Stainless Steel 180-7-45',
      },
      {
        value: '805-0071',
        label: 'Roof Hook Stainless Steel 180-7-35 Two-Fold Adjustable',
      },
      {
        value: '707-0204',
        label: 'Roof Hook Stainless Steel 150-9-49 Heavy Load – Comfort',
      },
      {
        value: '805-0051',
        label: 'Roof Hook Stainless Steel 150-9-49 Heavy Load',
      },
      {
        value: '805-0007',
        label: 'Roof Hook Stainless Steel 30-7-28 Slate Tiles',
      },
      {
        value: '805-0018',
        label: 'Roof Hook Stainless Steel 30-7-29 Plain Tiles',
      },
    ],
  },
]

export interface ExtraProd {
  code: string
  qty: number
}

export const extra_products_per_hook: Record<string, ExtraProd[]> = {
  '707-0204': [],
  '805-0051': [],
  '805-0007': [
    {
      code: '601-0115',
      qty: 1,
    },
    {
      code: '720-0041',
      qty: 1,
    },
  ],
  '805-0018': [
    {
      code: '601-0115',
      qty: 1,
    },
    {
      code: '720-0041',
      qty: 1,
    },
  ],
  '707-0202': [],
  '707-0212': [],
  '707-0036': [],
  '707-0201': [],
  '805-0047': [],
  '805-0010': [],
  '707-0050': [],
  '707-0214': [],
  '707-0213': [],
  '707-0053': [],
  '805-0071': [],
}

export const compatibilityRules: ComptibilityRules = {
  mountingRail: {
    profileCompatibility: {
      '610-0055': ['4/35'],
      '610-0097': ['4/35'],
      '610-0713': ['4/35'],
      '800-0992': ['4/35'],
      '800-1442': ['4/35'],
      '610-0714': ['6/40'],
      '800-0025': ['10/48'],
      '800-1132': ['10/48'],
      '800-1341': ['10/48'],
      '800-1413': ['10/48'],
      '610-0056': ['4/35'],
      '610-0096': ['4/35'],
      '610-0704': ['4/35'],
      '610-0705': ['4/35'],
      '800-0020': ['4/35'],
      '800-0021': ['4/35'],
      '800-1055': ['4/35'],
      '800-1317': ['4/35'],
      '800-1344': ['4/35'],
      '800-1405': ['4/35'],
      '800-1384': ['4/35'],
      '610-0070': ['6/40'],
      '800-0017': ['6/40'],
      '800-0019': ['6/40'],
      '800-1057': ['6/40'],
      '800-1319': ['6/40'],
      '800-1411': ['6/40'],
      '800-1412': ['6/40'],
    },
  },
  roofHook: {
    roofHookMaterialCompatibility: {
      '707-0202': ['stainlessSteel'],
      '707-0212': ['stainlessSteel'],
      '707-0036': ['stainlessSteel'],
      '707-0204': ['stainlessSteel'],
      '707-0201': ['stainlessSteel'],
      '805-0047': ['stainlessSteel'],
      '805-0010': ['stainlessSteel'],
      '707-0050': ['aluminium'],
      '707-0214': ['aluminium'],
      '707-0213': ['aluminium'],
      '707-0053': ['aluminium'],
      '805-0051': ['stainlessSteel'],
      '805-0071': ['stainlessSteel'],
      '805-0018': ['stainlessSteel'],
      '805-0007': ['stainlessSteel'],
    },
  },
}
