import { HardwareSupplierPresetConfig } from 'pages/ordering/configs'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { COLOR_PALETTE } from '../styles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: (props: {
    isWrapped: boolean
    isConnected: boolean
    isSelected: boolean
    numberOfAvailableDistributor: number | undefined
  }) => {
    return {
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      gap: props.isWrapped ? 15 : 10,
      border: props.isSelected && !props.isWrapped ? `1px solid ${COLOR_PALETTE.blue3}` : '1px solid #e7e7e7',
      borderRadius: 4,
    }
  },
  logo: (props: {
    isWrapped: boolean
    isConnected: boolean
    isSelected: boolean
    numberOfAvailableDistributor: number | undefined
  }) => {
    return {
      padding: props.isWrapped ? '0px 5px' : 0,
      width: props.isWrapped ? 56 : 'auto',
      height: props.isWrapped ? 28 : 32,
      objectFit: 'fill',
    }
  },
  logoWrapper: (props: {
    isWrapped: boolean
    isEnabled: boolean
    isConnected: boolean
    isSelected: boolean
    numberOfAvailableDistributor: number | undefined
  }) => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: props.isWrapped ? '1px solid #e7e7e7' : 'none',
      borderRadius: 4,
      cursor: props?.numberOfAvailableDistributor === 1 ? 'default' : 'pointer',
      pointerEvents: props.isConnected && props.isEnabled ? 'auto' : 'none',
      width: props.isWrapped ? 56 : 'auto',
      height: props.isWrapped ? 28 : 38,
      marginLeft: props.isWrapped ? 5 : 0,
    }
  },
}))

export type ExtendedHardwareSupplierConfig = HardwareSupplierPresetConfig & {
  isEnabled: boolean
  isConnected: boolean
  isSelected: boolean
  isVisible: boolean
}

const DistributorChip = ({
  isWrapped,
  config,
  button,
  icon,
  action,
  numberOfAvailableDistributor,
}: {
  isWrapped: boolean
  config: ExtendedHardwareSupplierConfig
  button?: React.ReactNode
  icon?: React.ReactNode
  action?: (supplier: HardwareSupplierFilterKeyType) => void
  numberOfAvailableDistributor?: number
}) => {
  const classes = useStyles({
    isWrapped,
    isEnabled: config.isEnabled,
    isConnected: config.isConnected,
    isSelected: config.isSelected,
    numberOfAvailableDistributor: numberOfAvailableDistributor,
  })

  return (
    <div className={classes.container}>
      <div
        className={classes.logoWrapper}
        onClick={() => {
          action?.(config.filterKey)
        }}
      >
        <img src={config.logoUrl} alt="logo" className={classes.logo} />
      </div>
      {config.isConnected && config.isEnabled ? icon : button}
    </div>
  )
}

export default DistributorChip
