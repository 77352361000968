import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useViewShow } from 'ducks/viewMode'
import Tooltip from 'elements/tooltip/Tooltip'
import { useFilterProjectErrors } from 'projectSections/errors/useFilterProjectErrors'
import { useSortedProjectErrors } from 'projectSections/errors/useSortedProjectErrors'
import { useEffect } from 'react'
import type { DesignTabs } from 'reducer/designer/view'
import { useFeatureFlag } from 'util/split'
import NextStepAction from './tab/NextStepAction'

const useTabStyle = makeStyles({
  root: {
    minWidth: 70,
    maxWidth: 70,
    minHeight: 50,
    padding: '5px 10px',
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'none',
    '&$selected': {
      boxShadow: '0px 4px 2px -2px rgb(0 0 0 / 12%), 0px -4px 2px -2px rgb(0 0 0 / 12%)',
      background: 'rgb(255,255,255)',
    },
  },
  errorIcon: {
    position: 'absolute',
    left: 5,
    top: 0,
    width: 20,
    height: 20,
    color: 'rgb(230, 37, 0)',
  },
  warningIcon: {
    position: 'absolute',
    left: 5,
    top: 0,
    width: 20,
    height: 20,
    color: '#f79522',
  },
  infoIcon: {
    position: 'absolute',
    left: 5,
    top: 0,
    width: 20,
    height: 20,
    color: 'rgb(44, 73, 157)',
  },
  selected: {},
})

// const checkSystemErrorCategory = (error) => {
//   for (let i = 0; i < error.length; i++) {
//     if (error[i].category === 'system') {
//       return true
//     }
//   }
// }

const StyledContent = ({ children, value, disableContents = false }) => {
  useEffect(() => {
    //show stringing when selectedTab is inverter
    if (value === 'inverters') {
      window.Designer.setStringVisibility(true)
    }
    return () => {
      if (value === 'inverters') {
        window.Designer.setStringVisibility(false)
      }
    }
  }, [])
  const position = !disableContents ? 'relative' : 'absolute'
  return (
    <Box style={{ padding: 8, position: position, top: 0 }}>
      <div>{children}</div>
    </Box>
  )
}

type TabPanelProps = {
  selectedTab: DesignTabs
  value: DesignTabs
  children: React.ReactNode
  disableContents: boolean
}

export const TabPanel = (props: TabPanelProps) => {
  const enablePanelTabsV2 = useFeatureFlag('new_design_tabs', 'on')
  const show = useViewShow('studio.tab_panels.' + props.value)

  const { children, value, selectedTab, ...other } = props
  if (value !== selectedTab) return null

  if (!show) {
    return null
  }

  return (
    <div
      role="tabpanel"
      style={{
        overflow: 'auto',
        overflowX: 'hidden',
        width: '100%',
        boxShadow: '-4px 0px 2px -2px rgb(0 0 0 / 12%)',
        display: 'flex',
        flexDirection: 'column',
      }}
      hidden={value !== selectedTab}
      id={`system-tabpanel-${value.replace(/ /g, '-')}`}
      aria-labelledby={`system-tab-${value.replace(/ /g, '-')}`}
      {...other}
    >
      <div style={{ flexGrow: 1 }}>
        {props.disableContents && (
          <Tooltip title="Updating sold system is disabled during Change Order mode" placement="right">
            <div
              style={{
                width: 260,
                height: '100%',
                zIndex: 1000,
                position: 'absolute',
                top: 0,
              }}
            />
          </Tooltip>
        )}
        <StyledContent children={children} value={value} />
      </div>
      {enablePanelTabsV2 && (
        <div style={{ position: 'sticky', bottom: 0, right: 0 }}>
          <NextStepAction currentTabValue={value} />
        </div>
      )}
    </div>
  )
}

export const StyledTab = (props) => {
  const classes = useTabStyle()
  const { errorCategories, hasShadingCalcsAwaitingTrigger, ...rest } = props
  const checkSystemError = !!useFilterProjectErrors([
    {
      categories: ['system'],
    },
  ]).length

  const sortedErrors = useSortedProjectErrors([
    {
      categories: errorCategories,
      systemIds: [window.editor?.selectedSystem?.uuid],
      severities: ['error', 'warning', 'info'],
    },
  ])
  const highestSeverity = sortedErrors[0]?.severity

  const show = useViewShow('studio.tabs.' + props.value)
  if (!show) {
    return null
  }

  return (
    <>
      <span style={{ position: 'relative' }}>
        <Tab classes={{ root: classes.root, selected: classes.selected }} {...rest} />
        {highestSeverity && (
          <InfoOutlinedIcon
            classes={{
              root:
                highestSeverity === 'error'
                  ? classes.errorIcon
                  : highestSeverity === 'warning'
                  ? classes.warningIcon
                  : classes.infoIcon,
            }}
          />
        )}
      </span>
      {checkSystemError
        ? hasShadingCalcsAwaitingTrigger && (
            <InfoOutlinedIcon fontSize="small" color="primary" style={{ position: 'absolute', left: 5, top: 15 }} />
          )
        : hasShadingCalcsAwaitingTrigger && (
            <InfoOutlinedIcon fontSize="small" color="primary" style={{ position: 'absolute', left: 5, top: 0 }} />
          )}
    </>
  )
}
