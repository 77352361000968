import _ from 'lodash'
import formatTariffSubmitValues from 'projectSections/sections/energy/formatTariffSubmitValues'
import formatWorkflowValues from 'projectSections/sections/manage/projectManagement/formatWorkflowValues'
import formatManageValues from 'projectSections/sections/manage3/formatManageValues'
import formatDesignSubmitValues from '../sections/design/formatDesignSubmitValues'
import formatUsageSubmitValues from '../sections/energy/formatUsageSubmitValues'
import formatSharedWithValues from '../sections/info/orgSharing/formatSharedWithValues'
import formatChangeOrderValues from '../sections/info/sale/formatChangeOrderValues'
import pipe from './pipe'

export const formatSubmitValues = (formValues, dirtyFields) => {
  const submitValues = _.pick(formValues, dirtyFields)
  try {
    pipe(
      formatUsageSubmitValues,
      formatDesignSubmitValues,
      formatSharedWithValues,
      formatTariffSubmitValues,
      formatWorkflowValues,
      formatManageValues,
      formatChangeOrderValues
    )(submitValues, formValues, dirtyFields)
  } catch (e) {
    console.warn('Error in formatSubmitValues: ', e)
    return _.pick(formValues, dirtyFields)
  }

  return submitValues
}
