import DualTiltIcon from 'icons/DualTiltIcon'
import FlushIcon from 'icons/FlushIcon'
import SingleTiltIcon from 'icons/SingleTiltIcon'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { PanelConfiguration } from 'types/global'
import { default as useStyles } from './styles'

type RackingSelectorPropTypes = {
  onChange: (newValue: PanelConfiguration) => void
  value: PanelConfiguration
}

const RackingSelector = ({ value, onChange }: RackingSelectorPropTypes) => {
  const classes = useStyles()
  const translate = useTranslate()

  const getOptCSSClassName = useCallback((optValue: PanelConfiguration, selectionValue: PanelConfiguration) => {
    return optValue === selectionValue
      ? classes.rackingSelectorOpt + ' ' + classes.rackingSelectorOptActive
      : classes.rackingSelectorOpt
  }, [])

  return (
    <div className={classes.rackingSelectorContainer}>
      <div className={getOptCSSClassName('STANDARD', value)} onClick={() => onChange('STANDARD')}>
        <FlushIcon highlight={value === 'STANDARD'} />
        <div className={classes.rackingSelectorOptLabel}>{translate('Flush')}</div>
      </div>
      <div className={getOptCSSClassName('TILT_RACK', value)} onClick={() => onChange('TILT_RACK')}>
        <SingleTiltIcon highlight={value === 'TILT_RACK'} />
        <div className={classes.rackingSelectorOptLabel}>{translate('Single-tilt')}</div>
      </div>
      <div className={getOptCSSClassName('DUAL_TILT_RACK', value)} onClick={() => onChange('DUAL_TILT_RACK')}>
        <DualTiltIcon highlight={value === 'DUAL_TILT_RACK'} />
        <div className={classes.rackingSelectorOptLabel}>{translate('Dual-tilt')}</div>
      </div>
    </div>
  )
}

export default RackingSelector
