import { Divider, MenuItem, Tooltip } from '@material-ui/core'
import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined'
import { orgSelectors } from 'ducks/orgs'
import { CustomNumberField } from 'elements/field/CustomNumberField'
import FrameHeightLandscapeIcon from 'icons/FrameHeightLandscapeIcon'
import FrameHeightPortraitIcon from 'icons/FrameHeightPortraitIcon'
import FrameSpacingLandscapeIcon from 'icons/FrameSpacingLandscapeIcon'
import FrameSpacingPortraitIcon from 'icons/FrameSpacingPortraitIcon'
import FrameWidthLandscapeIcon from 'icons/FrameWidthLandscapeIcon'
import FrameWidthPortraitIcon from 'icons/FrameWidthPortraitIcon'
import ModuleSpacingLandscapeIcon from 'icons/ModuleSpacingLandscapeIcon'
import ModuleSpacingPortraitIcon from 'icons/ModuleSpacingPortraitIcon'
import RidgeSpacingLandscapeIcon from 'icons/RidgeSpacingLandscapeIcon'
import RidgeSpacingPortraitIcon from 'icons/RidgeSpacingPortraitIcon'
import ValleySpacingLandscapeIcon from 'icons/ValleySpacingLandscapeIcon'
import ValleySpacingPortraitIcon from 'icons/ValleySpacingPortraitIcon'
import { Switch, TextField } from 'opensolar-ui'
import { memo, useCallback, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { useStudioSignals } from 'Studio/signals/useStudioSignals'
import { ModuleGridType, StudioItem } from 'types/global'
import { MeasurementUnits } from 'types/orgs'
import { StudioFieldContainer } from '../components/StudioFieldContainer'
import { fromMeters, toMeters, trimDecimalPlaces } from './helpers'
import useStyles from './styles'

type AdvancedSettingsPropTypes = {
  moduleGrid: ModuleGridType
  allowEdit?: boolean
}

const AdvancedSettings = memo(({ moduleGrid, allowEdit = false }: AdvancedSettingsPropTypes) => {
  const [_refresh, setRefresh] = useState<number>(0)

  const translate = useTranslate()
  const classes = useStyles()
  const measurements = useSelector(orgSelectors.getMeasurementUnits) || MeasurementUnits.metric

  const moduleSpacing = moduleGrid.moduleSpacing // these are in meters
  const groupSpacing = moduleGrid.groupSpacing // these are in meters
  const modulesPerCol = moduleGrid.modulesPerCol()
  const modulesPerRow = moduleGrid.modulesPerRow()
  const trackingMode = moduleGrid.trackingMode()
  const panelPlacement = moduleGrid.panelPlacement || 'roof'
  const moduleLayoutOffset = moduleGrid.moduleLayoutOffset()
  const moduleLayout = moduleGrid.moduleLayout()
  const groundCoverageRatio = trimDecimalPlaces(moduleGrid.calculateGroundCoverageRatio(), 2)
  const isDualTilt = moduleGrid.getPanelConfiguration() === 'DUAL_TILT_RACK'

  const onObjectChanged = useCallback(
    (obj: StudioItem, _prop: string) => {
      if (obj !== moduleGrid) return
      // force a re-render if the module grid has changed
      // wrap the ctr to keep the value small
      setRefresh((prev) => (prev < 999 ? prev + 1 : 0))
    },
    [moduleGrid]
  )

  useStudioSignals(onObjectChanged, ['objectChanged'], undefined, { trackHandler: true })

  const setOffsetRows = (value: boolean): void => {
    window.editor.execute(new window.RedrawModulesCommand(moduleGrid, 'moduleLayoutOffset', value, !value))
  }

  const setPanelPlacement = (value: 'roof' | 'ground') => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        moduleGrid,
        'panelPlacement',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
    let system = moduleGrid.getSystem()
    window.editor.execute(new window.SetValueCommand(system, 'systemPanelPlacement', system.getSystemPanelPlacement()))
  }

  const setModuleSpacing = (horizontal: number, vertical: number): void => {
    window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'moduleSpacing', [horizontal, vertical]))
  }

  const setModulesPerCol = (value: number): void => {
    window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'modulesPerCol', value))
  }

  const setModulesPerRow = (value: number): void => {
    window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'modulesPerRow', value))
  }

  const setGroupSpacing = (horizontal: number, vertical: number): void => {
    window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'groupSpacing', [horizontal, vertical]))
  }

  const setTrackingMode = (value: unknown): void => {
    window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'trackingMode', value))
  }

  return (
    <>
      {!isDualTilt && (
        <>
          <div className={classes.fieldsRow}>
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Horiz. Module Spacing')}
              field={
                <CustomNumberField
                  name="moduleGridModuleSpacingX"
                  value={fromMeters(moduleSpacing[0], measurements)}
                  step={measurements === 'imperial' ? 0.5 : 0.1} // 0.5in for imperial or 0.1m for metric
                  endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                  disabled={!allowEdit}
                  maxDecimalPlaces={4}
                  wrapperStyles={{ marginBottom: 0 }}
                  onChange={(newValue, _event) => {
                    // what we save to studio MUST always be in meters
                    setModuleSpacing(toMeters(newValue, measurements), moduleSpacing[1])
                  }}
                />
              }
            />
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Vert. Module Spacing')}
              field={
                <CustomNumberField
                  name="moduleGridModuleSpacingY"
                  value={fromMeters(moduleSpacing[1], measurements)}
                  step={measurements === 'imperial' ? 0.5 : 0.1} // 0.5in for imperial or 0.1m for metric
                  endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                  disabled={!allowEdit}
                  maxDecimalPlaces={4}
                  wrapperStyles={{ marginBottom: 0 }}
                  onChange={(newValue, _event) => {
                    // what we save to studio MUST always be in meters
                    setModuleSpacing(moduleSpacing[0], toMeters(newValue, measurements))
                  }}
                />
              }
            />
          </div>
          <div className={classes.fieldsRow}>
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Modules Per Column')}
              field={
                <TextField
                  disabled={!allowEdit}
                  select
                  label={null}
                  value={modulesPerCol}
                  onChange={(event) => {
                    setModulesPerCol(parseInt(event.target.value))
                  }}
                  fullWidth
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Modules Per Row')}
              field={
                <TextField
                  disabled={!allowEdit}
                  select
                  label={null}
                  value={modulesPerRow}
                  onChange={(event) => {
                    setModulesPerRow(parseInt(event.target.value))
                  }}
                  fullWidth
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
          </div>
          <div className={classes.fieldsRow}>
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Horiz. Group Spacing')}
              field={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomNumberField
                    name="moduleGridGroupSpacingX"
                    value={fromMeters(groupSpacing[0], measurements)}
                    step={measurements === 'imperial' ? 1 : 0.5} // 1in for imperial or 0.5m for metric
                    endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                    disabled={!allowEdit || modulesPerCol === 1}
                    maxDecimalPlaces={4}
                    wrapperStyles={{ marginBottom: 0 }}
                    onChange={(newValue, _event) => {
                      // what we save to studio MUST always be in meters
                      setGroupSpacing(toMeters(newValue, measurements), groupSpacing[1])
                    }}
                  />
                  {modulesPerCol > 1 && groupSpacing[0] < moduleSpacing[0] && (
                    <Tooltip
                      title={
                        <span>
                          {translate('Horizontal group spacing is shorter than the horizontal module spacing.')}
                        </span>
                      }
                      style={{ padding: 0, marginLeft: 10 }}
                    >
                      <ErrorOutlineOutlined classes={{ root: classes.warningIcon }} />
                    </Tooltip>
                  )}
                </div>
              }
            />

            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Vert. Group Spacing')}
              field={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomNumberField
                    name="moduleGridGroupSpacingY"
                    value={fromMeters(groupSpacing[1], measurements)}
                    step={measurements === 'imperial' ? 1 : 0.5} // 1in for imperial or 0.5m for metric
                    endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                    disabled={!allowEdit || modulesPerRow === 1}
                    maxDecimalPlaces={4}
                    wrapperStyles={{ marginBottom: 0 }}
                    onChange={(newValue, _event) => {
                      // what we save to studio MUST always be in meters
                      setGroupSpacing(groupSpacing[0], toMeters(newValue, measurements))
                    }}
                  />
                  {modulesPerRow > 1 && groupSpacing[1] < moduleSpacing[1] && (
                    <Tooltip
                      title={
                        <span>{translate('Vertical group spacing is shorter than the vertical module spacing.')}</span>
                      }
                      style={{ padding: 0, marginLeft: 10 }}
                    >
                      <ErrorOutlineOutlined classes={{ root: classes.warningIcon }} />
                    </Tooltip>
                  )}
                </div>
              }
            />
          </div>
        </>
      )}

      {isDualTilt && (
        <>
          <div className={classes.fieldsRow}>
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Ridge Spacing')}
              info={
                <div style={{ width: 250 }}>
                  {moduleLayout === 'landscape' ? <RidgeSpacingLandscapeIcon /> : <RidgeSpacingPortraitIcon />}
                  <div style={{ marginTop: 5 }}>
                    {translate('Ridge spacing refers to the distance between the peaks of the dual-tilt.')}
                  </div>
                </div>
              }
              field={
                <CustomNumberField
                  name="moduleGridRidgeSpacing"
                  value={fromMeters(moduleSpacing[1], measurements)}
                  step={measurements === 'imperial' ? 0.5 : 0.1} // 0.5in for imperial or 0.1m for metric
                  endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                  disabled={!allowEdit}
                  maxDecimalPlaces={4}
                  wrapperStyles={{ marginBottom: 0 }}
                  onChange={(newValue, _event) => {
                    // what we save to studio MUST always be in meters
                    setModuleSpacing(moduleSpacing[0], toMeters(newValue, measurements))
                  }}
                />
              }
            />

            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Valley Spacing')}
              info={
                <div style={{ width: 250 }}>
                  {moduleLayout === 'landscape' ? <ValleySpacingLandscapeIcon /> : <ValleySpacingPortraitIcon />}
                  <div style={{ marginTop: 5 }}>
                    {translate('Valley spacing denotes the width of the walkway at the base.')}
                  </div>
                </div>
              }
              field={
                <CustomNumberField
                  name="moduleGridValleySpacing"
                  value={fromMeters(groupSpacing[1], measurements)}
                  step={measurements === 'imperial' ? 1 : 0.5} // 1in for imperial or 0.5m for metric
                  endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                  disabled={!allowEdit || modulesPerRow === 1}
                  maxDecimalPlaces={4}
                  wrapperStyles={{ marginBottom: 0 }}
                  onChange={(newValue, _event) => {
                    // what we save to studio MUST always be in meters
                    setGroupSpacing(groupSpacing[0], toMeters(newValue, measurements))
                  }}
                />
              }
            />
          </div>

          <div className={classes.fieldsRow}>
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Module Spacing')}
              info={
                <div style={{ width: 250 }}>
                  {moduleLayout === 'landscape' ? <ModuleSpacingLandscapeIcon /> : <ModuleSpacingPortraitIcon />}
                  <div style={{ marginTop: 5 }}>
                    {translate('Module spacing is the tiny gap between each individual panel.')}
                  </div>
                </div>
              }
              field={
                <CustomNumberField
                  name="moduleGridModuleSpacingX"
                  value={fromMeters(moduleSpacing[0], measurements)}
                  step={measurements === 'imperial' ? 0.5 : 0.1} // 0.5in for imperial or 0.1m for metric
                  endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                  disabled={!allowEdit}
                  maxDecimalPlaces={4}
                  wrapperStyles={{ marginBottom: 0 }}
                  onChange={(newValue, _event) => {
                    // what we save to studio MUST always be in meters
                    setModuleSpacing(toMeters(newValue, measurements), moduleSpacing[1])
                  }}
                />
              }
            />

            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Frame Spacing')}
              info={
                <div style={{ width: 250 }}>
                  {moduleLayout === 'landscape' ? <FrameSpacingLandscapeIcon /> : <FrameSpacingPortraitIcon />}
                  <div style={{ marginTop: 5 }}>
                    {translate(
                      'Frame spacing refers to the distance between modules in the North/South direction, separating blocks of modules in the same row.'
                    )}
                  </div>
                </div>
              }
              field={
                <CustomNumberField
                  name="moduleGridFrameSpacing"
                  value={fromMeters(groupSpacing[0], measurements)}
                  step={measurements === 'imperial' ? 1 : 0.5} // 1in for imperial or 0.5m for metric
                  endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                  disabled={!allowEdit || modulesPerCol === 1}
                  maxDecimalPlaces={4}
                  wrapperStyles={{ marginBottom: 0 }}
                  onChange={(newValue, _event) => {
                    // what we save to studio MUST always be in meters
                    setGroupSpacing(toMeters(newValue, measurements), groupSpacing[1])
                  }}
                />
              }
            />
          </div>

          <div className={classes.fieldsRow}>
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Frame Width')}
              info={
                <div style={{ width: 250 }}>
                  {moduleLayout === 'landscape' ? <FrameWidthLandscapeIcon /> : <FrameWidthPortraitIcon />}
                  <div style={{ marginTop: 5 }}>{translate('Frame Width')}</div>
                </div>
              }
              field={
                <TextField
                  disabled={!allowEdit || !isDualTilt}
                  select
                  label={null}
                  value={modulesPerCol}
                  onChange={(event) => {
                    setModulesPerCol(parseInt(event.target.value))
                  }}
                  fullWidth
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Frame Height')}
              info={
                <div style={{ width: 250 }}>
                  {moduleLayout === 'landscape' ? <FrameHeightLandscapeIcon /> : <FrameHeightPortraitIcon />}
                  <div style={{ marginTop: 5 }}>{translate('Frame Height')}</div>
                </div>
              }
              field={
                <TextField
                  disabled={!allowEdit}
                  select
                  label={null}
                  value={modulesPerRow / 2}
                  onChange={(event) => {
                    setModulesPerRow(parseInt(event.target.value) * 2)
                  }}
                  fullWidth
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
          </div>
        </>
      )}

      <Divider style={{ marginTop: 15, marginBottom: 15 }} />

      <div className={classes.fieldsRow}>
        <StudioFieldContainer
          style={{ width: '50%' }}
          label={translate('Tracking Mode')}
          field={
            <TextField
              disabled={!allowEdit}
              select
              value={trackingMode}
              onChange={(event) => {
                setTrackingMode(event.target.value)
              }}
              fullWidth
            >
              {window.trackingModes &&
                window.trackingModes.map((trackingMode) => (
                  <MenuItem key={trackingMode.id} value={trackingMode.id}>
                    {translate(trackingMode.name)}
                  </MenuItem>
                ))}
            </TextField>
          }
        />
        <StudioFieldContainer
          style={{ width: '50%' }}
          label={translate('Panel Placement')}
          field={
            <TextField
              disabled={!allowEdit}
              select
              value={panelPlacement}
              onChange={(event) => {
                setPanelPlacement(event.target.value as 'roof' | 'ground')
              }}
              fullWidth
            >
              <MenuItem key="0" value={'roof'}>
                {translate('Roof')}
              </MenuItem>
              <MenuItem key="1" value={'ground'}>
                {translate('Ground')}
              </MenuItem>
            </TextField>
          }
        />
      </div>

      <div className={classes.fieldsRow} style={{ alignItems: 'start' }}>
        <StudioFieldContainer
          style={{ width: '50%' }}
          label={translate('Offset')}
          field={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                disabled={!allowEdit}
                checked={moduleLayoutOffset}
                onChange={(_event, checked) => {
                  setOffsetRows(checked)
                }}
              />
              <Tooltip
                title={
                  <span>{translate('Applies a horizontal shift to the panel positions in alternating order.')}</span>
                }
              >
                <ErrorOutlineOutlined classes={{ root: classes.infoIcon }} />
              </Tooltip>
            </div>
          }
        />

        <StudioFieldContainer
          style={{ width: '50%' }}
          label={translate('Ground Coverage Ratio')}
          field={<h3>{groundCoverageRatio}</h3>}
        />
      </div>
    </>
  )
})

export default AdvancedSettings
