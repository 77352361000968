import { BomGeneratorAbstract } from '../../bom_generators/BomGeneratorAbstract'
import {
  PerColumnRailBomGeneratorAbstract,
  VerticalRailPosition,
} from '../../bom_generators/PerColumnRailBomGeneratorAbstract'
import {
  HorizontalRailPosition,
  PerRowRailBomGeneratorAbstract,
} from '../../bom_generators/PerRowRailBomGeneratorAbstract'
import {
  BasicSpacingLayoutAdjusterAbstract,
  SpacingRuleSet,
} from '../../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../../MountingSystemAbstract'
import { CutAndSplicerAbstract } from '../../post_processors/CutAndSplicerAbstract'
import { RoofHookAdderAbstract } from '../../post_processors/RoofHookAdderAbstract'
import {
  CompatibilityParameters,
  Component,
  Direction,
  Fastener,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  Panel,
  PanelBlock,
  RailComponent,
  RailType,
} from '../../types'

import { ExtraProd, extra_products_per_hook, findProd, getDefaultProd, gmbhProducts } from './MSPitchedProLine_products'

const spacingRuleSet: SpacingRuleSet = {
  interPanel: { horizontalSpacing: 19, verticalSpacing: 19 },
}
class MSPitchedProLineSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = spacingRuleSet
}

const railOverhang = 41

const addExtras = (hookName: string | undefined, itemsInBom: MountingCalcResult): MountingCalcResult => {
  if (!hookName) {
    return itemsInBom
  }
  const extraProds = extra_products_per_hook[hookName]
  if (extraProds.length === 0) {
    return itemsInBom
  }
  const hooksInBomQty = itemsInBom.items.filter((item) => item.name === hookName).length
  const transformedData = extraProds.reduce(
    (acc, extraProduct: ExtraProd) => {
      const qty = hooksInBomQty * extraProduct.qty
      const formattedProd = Array.from({ length: qty }, () => ({
        name: extraProduct.code,
        components: [],
      }))
      const updatedItems = acc.items.concat(formattedProd)
      return { ...acc, items: updatedItems }
    },
    { ...itemsInBom }
  )
  return transformedData
}

class MSPitchedProLineBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const starterBom = await this.generateStarterBom(result)
    return this.postProcess(starterBom)
  }

  generateStarterBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    if (this.block.orientation === 'portrait') {
      return this.generateBomFrom(MSPitchedProLinePortraitBomGenerator, result)
    } else {
      return this.generateBomFrom(MSPitchedProLineLandscapeBomGenerator, result)
    }
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    const resultWithRoofHooks = new MSPitchedProLineRoofHookAdder(this.input).process(result)
    const resultWithSplices = new MSPitchedProLineRailSplicer(this.input).process(resultWithRoofHooks)
    const resultWithExtras = addExtras(this.input.options.roofHook, resultWithSplices)
    return resultWithExtras
  }
}

export class MSPitchedProLine extends MountingSystemAbstract {
  layoutAdjusterClass = MSPitchedProLineSpacingAdjuster
  bomGeneratorClass = MSPitchedProLineBomGenerator

  getFastener(): Fastener {
    const railDirection = this.input.panelBlocks[0].moduleGrid.moduleLayout() === 'portrait' ? 'horizontal' : 'vertical'
    return chooseFastener(chooseHook(this.input, null, chooseRail(this.input, railDirection)))
  }

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      slopeRange: [0, 60],
      moduleThicknessRange: [30, 50],
      roofTypes: ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
      roofTypeRequired: false,
      featureFlag: 'mounting_MSPitchedProLine',
    }
  }
}

class MSPitchedProLinePortraitBomGenerator extends PerRowRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'horizontal')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.height * 0.6) / 2

    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.height - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}

class MSPitchedProLineLandscapeBomGenerator extends PerColumnRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'vertical')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): VerticalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.width * 0.6) / 2

    return [
      {
        left: distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
      {
        left: panel.width - distanceFromRailToPanelEdge,
        top: -railOverhang,
        bottom: railOverhang,
      },
    ]
  }
}

function chooseRail(input: MountingCalcInput, direction: Direction): RailComponent {
  const componentCode = input.options.mountingRail as string
  return {
    type: MountingComponentType.RAIL,
    railType: 'continuous',
    direction,
    name: componentCode,
    length: 0,
    top: 0,
    left: 0,
  }
}

function chooseHook(input: MountingCalcInput, panelBlock: PanelBlock | null, rail: RailComponent) {
  const componentCode = input.options.roofHook as string
  return [
    {
      name: componentCode,
      components: [
        {
          name: componentCode,
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    },
  ]
}

function chooseEndCap(input: MountingCalcInput): Item {
  const componentCode = getDefaultProd(
    gmbhProducts,
    'endCap',
    input.options.endCapColour,
    input.options.profile,
    input.options.panelThicknessRange
  )
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.OTHER,
        left: -18.1,
        top: -24.8,
      },
    ],
  }
}

function chooseEndClamp(input: MountingCalcInput): Item {
  const componentCode = getDefaultProd(
    gmbhProducts,
    'endClamp',
    input.options.clampColour,
    input.options.profile,
    input.options.panelThicknessRange
  )
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseMidClamp(input: MountingCalcInput): Item {
  const componentCode = getDefaultProd(
    gmbhProducts,
    'midClamp',
    input.options.clampColour,
    input.options.profile,
    input.options.panelThicknessRange
  )
  return {
    name: componentCode,
    components: [
      {
        name: componentCode,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseFastener(hook: Item[]): Fastener {
  const hookName = hook[0].name
  const hookProd = findProd(gmbhProducts, hookName)
  return {
    includedInRoofHookProduct: hookProd['roof_fixing__included'] === 'TRUE' ? true : false,
    name: hookProd['roof_fixing__code'],
    qtyPerRoofHook: parseInt(hookProd['roof_fixing__qty']),
    length: 80,
    diameter: 6.3,
    components: [],
  }
}

class MSPitchedProLineRoofHookAdder extends RoofHookAdderAbstract {
  spacingRuleSet = spacingRuleSet
  railOverhang = railOverhang

  getRailType(input: MountingCalcInput): RailType {
    return 'continuous'
  }

  chooseHook(input: MountingCalcInput, panelBlock: PanelBlock, rail: RailComponent) {
    return chooseHook(input, panelBlock, rail)
  }

  chooseFastener(hooks) {
    return chooseFastener(hooks)
  }
}

class MSPitchedProLineRailSplicer extends CutAndSplicerAbstract {
  isTarget(component: Component): boolean {
    return component?.type === MountingComponentType.RAIL
  }

  chooseSplice(railName: string, input: MountingCalcInput): Item[] {
    const componentCode = getDefaultProd(
      gmbhProducts,
      'railSplice',
      input.options.clampColour,
      input.options.profile,
      input.options.panelThicknessRange
    )

    return [
      {
        name: componentCode,
        components: [
          {
            type: MountingComponentType.SPLICE,
            name: componentCode,
            top: 0,
            left: 0,
          },
        ],
      },
    ]
  }

  getFullLength(railName: string): number {
    return parseInt(findProd(gmbhProducts, railName).length)
  }
}
