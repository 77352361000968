import { makeStyles } from '@material-ui/core/styles'
import { getSystemName } from 'Designer/hooks/useSystemName'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { projectViewSettingsActions, projectViewSettingsSelectors } from 'ducks/projectViewSettings'
import ActionsIconButton from 'elements/button/ActionsIconButton'
import {
  HourGlassOutlineIcon,
  IconButton,
  SendOutlineIcon,
  styled,
  TextInputOutlineIcon,
  UserProfileGroup,
} from 'opensolar-ui'
import { getCustomFields } from 'projectSections/sections/info/customFields/CustomFieldsContent'
import SoldChip from 'projectSections/sections/payments/SoldChip'
import { useTranslate } from 'ra-core'
import { useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import { formatDate } from 'util/date'
import SelectChipField from '../common/SelectChipField'
import { COLOR_PALETTE, IconButtonTooltip } from '../styles'
import CustomFieldsModal from './elements/CustomFieldsModal'
import ProjectHistoryDrawer from './elements/history/ProjectHistoryDrawer'
import ProjectAddress from './elements/ProjectAddress'
import ProjectDeleteButton from './elements/ProjectDeleteButton'
import { ProjectDuplicateButton } from './elements/ProjectDuplicateButton'
import ShareProposalLinkModal from './elements/sharing/ShareProposalLinkModal'

const useStyles = makeStyles((theme: Theme) => ({
  screenshot: {
    width: 100,
    height: 75,
    borderRadius: 7,
    outline: '1px solid blue',
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 'auto',
  },
  icon: {
    marginBottom: 32,
    marginLeft: 10,
  },
  select: {
    height: 30,
    fontSize: 14,
    backgroundColor: '#f1f1f1',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid ' + COLOR_PALETTE.grey2,
    },
    '& .MuiChip-root': {
      marginRight: 8,
    },
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    gap: 5,
  },
  chip: {
    marginRight: 5,
    height: '16px !important',
  },
}))

const ActionsWrapper = styled('div')({
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
})

const FlexWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
})

const DetailHeader = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const project: ProjectType = useFormState().values as ProjectType
  const org = useSelector(orgSelectors.getOrg)
  const orgId = useSelector(authSelectors.getOrgId)
  const historyOpen = useSelector(projectViewSettingsSelectors.getProjectHistoryOpen)
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage'))
  const [systemSelected, setSystemSelected] = useState(project.systems?.length ? project.systems[0]?.url : undefined)
  const [customFieldsOpen, setCustomFieldsOpen] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const customFields = useMemo(() => getCustomFields(org?.custom_fields, 'Project'), [])
  const isProjectShared = project.shared_with ?? []

  const today = new Date().toISOString().split('T')[0]
  const installationDate = formatDate(project.installation_date) ?? ''

  const soldInstalledChip = () => {
    const isInstalled = project.project_installed !== null
    const isSold = project.project_sold !== null
    if (isInstalled && (installationDate <= today || !installationDate)) {
      return translate('Installed')
    }
    if (isSold) {
      return translate('Sold')
    }
    return undefined
  }

  return (
    <FlexWrapper>
      {/* <div className={classes.screenshot} /> */}
      <ProjectAddress />
      <div className={classes.tags}>
        {/* <ReferenceInput
          label={''}
          resource={'projects'}
          reference="systems"
          source="system_sold"
          filter={{
            project: project ? project.id : null,
            visible_to: orgId,
          }}
          sort={{ field: 'order', order: 'ASC' }}
          allowEmpty
        >
          <SystemSelectField
            systemSelected={systemSelected}
            onChange={(e) => {
              setSystemSelected(e.target.value)
            }}
          />
        </ReferenceInput> */}
        {systemSelected && systemSelected === project.system_sold && <SoldChip chipText={soldInstalledChip()} />}
      </div>
      {isProjectShared?.length > 0 && (
        <div className={classes.icon}>
          <UserProfileGroup size={26} color={'gray'} />
        </div>
      )}
      <div className={classes.actions}>
        <IconButtonTooltip title={translate('Activity')}>
          <IconButton
            color="secondary"
            variant="squared"
            onClick={() => {
              dispatch(projectViewSettingsActions.setHistoryOpen(!historyOpen))
            }}
          >
            <HourGlassOutlineIcon variant={3} size={20} />
          </IconButton>
        </IconButtonTooltip>
        {!!customFields.length && (
          <IconButtonTooltip title={translate('Custom Fields')}>
            <IconButton
              color="secondary"
              variant="squared"
              onClick={() => {
                setCustomFieldsOpen(true)
              }}
              disabled={!allowEdit}
            >
              <TextInputOutlineIcon variant={1} size={20} />
            </IconButton>
          </IconButtonTooltip>
        )}
        <IconButtonTooltip title={translate('Share Proposal')}>
          <IconButton
            color="secondary"
            variant="squared"
            onClick={() => {
              setShowShareModal(true)
            }}
            disabled={!allowEdit}
          >
            <SendOutlineIcon variant={1} size={20} />
          </IconButton>
        </IconButtonTooltip>

        <ActionsIconButton disabled={!allowEdit}>
          <ActionsWrapper>
            {orgId && <ProjectDuplicateButton orgId={orgId} projectId={project.id} />}
            <ProjectDeleteButton project={project} />
          </ActionsWrapper>
        </ActionsIconButton>
      </div>
      <CustomFieldsModal
        open={customFieldsOpen}
        onClose={() => {
          setCustomFieldsOpen(false)
        }}
      />
      <ProjectHistoryDrawer
        open={historyOpen}
        onClose={() => {
          dispatch(projectViewSettingsActions.setHistoryOpen(false))
        }}
      />
      <ShareProposalLinkModal
        open={showShareModal}
        onClose={() => {
          setShowShareModal(false)
        }}
      />
    </FlexWrapper>
  )
}

const SystemSelectField = (props) => {
  const translate = useTranslate()
  const { choices, systemSelected, onChange } = props
  const displaySystemName = (sys) => {
    return getSystemName(sys, translate)
  }
  return (
    <SelectChipField
      choices={choices}
      chipLabel={translate('System')}
      optionValue={'url'}
      optionText={displaySystemName}
      value={systemSelected}
      onChange={onChange}
    />
  )
}

export default DetailHeader
