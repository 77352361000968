import { authSelectors } from 'ducks/auth'
import { Button, Chip, styled, TableIcon } from 'opensolar-ui'
import { COLOR_PALETTE, getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

const Wrapper = styled('div')({
  width: '100%',
  margin: '10px 0',
  padding: '10px 0',
  overflowX: 'auto',
  borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
})

const ViewButton = styled(Button)(({ theme }) => ({
  fontWeight: 450,
  color: COLOR_PALETTE.iconColor,
  '&.active': {
    color: theme.palette.info.contrastText,
  },
  '& .btnLabel': {
    margin: '0 5px',
  },
}))

const StyledChip = styled(Chip)({
  height: 18,
  fontSize: 10,
  borderRadius: 3,
})

const ButtonContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginRight: 15,
  flex: '0 0 auto',
}))

const Highlight = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: -10,
  width: '100%',
  borderBottom: '2px solid ' + theme.palette.info.contrastText,
}))

const DEFAULT_PROJECT_VIEWS = {
  all: {
    label: 'All Projects',
    filter: {},
  },

  // kanban: {
  //   icon: <ColumnVerticalIcon />,
  //   label: 'Kanban',
  // },
  unsold: {
    label: 'Unsold',
    filter: {
      milestone: '0,1,2,3',
      project_sold: false,
      project_installed: false,
    },
  },
  sold: {
    label: 'Sold',
    filter: {
      milestone: '0,1,2,3',
      project_sold: true,
      project_installed: false,
    },
  },
  installed: {
    label: 'Installed',
    filter: {
      milestone: '0,1,2,3',
      project_installed: true,
    },
  },
  others: {
    label: 'Others',
    filter: {
      milestone: 4,
    },
  },
}

const getDefaultFilter = (filterValues) => {
  const filterMatch = Object.keys(DEFAULT_PROJECT_VIEWS).find((x) => {
    if (Object.keys(filterValues).length === 0) return 'all'
    const filter = DEFAULT_PROJECT_VIEWS[x].filter
    const matchedFilters = Object.keys(filterValues).filter((y) => filterValues[y] === filter[y])
    return matchedFilters.length === Object.keys(filterValues).length
  })

  return filterMatch ? Object.keys(DEFAULT_PROJECT_VIEWS).indexOf(filterMatch) : null
}

const restClientInstance = restClient(window.API_ROOT + '/api')
const ViewToolbar = (props) => {
  const translate = useTranslate()
  const classes = getProjectSectionStyles()
  const [viewSelected, setViewSelected] = useState(getDefaultFilter(props.filterValues) || 0)
  const [loadedData, setLoadedData] = useState<object>()
  const orgId = useSelector(authSelectors.getOrgId)
  useEffect(() => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/project_milestone_count/',
    })
      .then((response) => {
        setLoadedData(response.data)
      })
      .catch(() => {
        setLoadedData({})
      })
  }, [])

  const handleFilterChange = (filter) => {
    props.setFilters({ ...filter })
  }

  useEffect(() => {
    const filterMatch = getDefaultFilter(props.filterValues)
    if (filterMatch !== null) setViewSelected(filterMatch)
  }, [props.filterValues])

  return (
    <Wrapper className={classes.row}>
      {Object.keys(DEFAULT_PROJECT_VIEWS).map((key, i) => {
        const value = DEFAULT_PROJECT_VIEWS[key]
        const count = loadedData ? (Object.keys(loadedData).includes(key) ? loadedData[key] : loadedData['all']) : 0
        return (
          <ButtonContainer key={'filter-' + i}>
            <ViewButton
              onClick={() => {
                setViewSelected(i)
                handleFilterChange(value.filter)
              }}
              variant="text"
              color="secondary"
              startIcon={value.icon || <TableIcon />}
              className={i === viewSelected ? 'active' : undefined}
            >
              <span className="btnLabel">{translate(value.label)}</span>
              <StyledChip
                label={count}
                color={i === viewSelected ? 'info' : 'default'}
                style={{ color: i === viewSelected ? undefined : COLOR_PALETTE.iconColor }}
              />
            </ViewButton>
            {i === viewSelected && <Highlight />}
          </ButtonContainer>
        )
      })}
      {/* <Button startIcon={<Add />} variant="text" color="default">
        {translate('Add View')}
      </Button> */}
    </Wrapper>
  )
}

export default ViewToolbar
