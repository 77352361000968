import FileCloudDownload from '@material-ui/icons/CloudDownloadOutlined'
import ActionDescription from '@material-ui/icons/DescriptionOutlined'
import AvPlayCircleOutline from '@material-ui/icons/PlayCircleOutlineOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import GenericButton from 'elements/button/GenericButton'
import { useTranslate } from 'react-admin'
import { useInView } from 'react-intersection-observer'
import Masonry from 'react-masonry-component'
import styles from './styles/SystemComponentStyles'

export const SystemComponentContainer = ({ children }) => (
  <div>
    <Masonry options={{ transitionDuration: false, gutter: 20 }}>{children}</Masonry>
  </div>
)

const renderExhibitButtons = (
  videoUrl,
  productDetails,
  specSheetUrl,
  showDialog,
  proposalData,
  translate,
  componentDetails
) => {
  var availableExhibitButtons = []

  if (videoUrl) {
    availableExhibitButtons.push({
      icon: (
        <AvPlayCircleOutline
          style={{
            ...styles.exhibitButtons.flatIcon,
            fill: proposalData.myeStyles.textColorOnHighlight,
            marginRight: 1,
          }}
        />
      ),
      label: 'Play',
      action: function () {
        showDialog('Video', { videoUrl: videoUrl })
        logAmplitudeEvent('proposal_card_component_clicked', { component: 'video', componentDetails })
      },
      type: 'flat',
    })
  }

  if (productDetails) {
    availableExhibitButtons.push({
      icon: <ActionDescription style={styles.exhibitButtons.icon} />,
      label: 'Details',
      action: function () {
        showDialog('Detail', { productDetails: productDetails })
        logAmplitudeEvent('proposal_card_component_clicked', { component: 'detailsheet', componentDetails })
      },
      type: 'text',
    })
  }

  if (specSheetUrl) {
    availableExhibitButtons.push({
      icon: <FileCloudDownload style={styles.exhibitButtons.icon} />,
      label: 'Specs',
      action: function () {
        window.open(specSheetUrl)
        logAmplitudeEvent('proposal_card_component_clicked', { component: 'specsheet', componentDetails })
      },
      type: 'text',
    })
  }

  const renderButtons = availableExhibitButtons.map((button, i) => {
    // TODO: GenericButton was temporary used to bridge Material UI v0
    // use elements/button/Button.tsx instead
    return (
      <div key={i}>
        {button.type === 'flat' ? (
          <GenericButton
            startIcon={button.icon}
            onClick={button.action}
            backgroundColor={proposalData.myeStyles.highlightColor}
            backgroundColorOnHover={proposalData.myeStyles.highlightColorInteraction}
            labelWrapperStyle={{
              ...styles.exhibitButtons.flatText,
              color: proposalData.myeStyles.textColorOnHighlight,
            }}
            labelStyle={{ display: 'flex' }}
            startIconStyle={{ width: 'unset', height: 'unset', margin: 0 }}
            style={{
              height: 20,
              lineHeight: '12px',
              minWidth: '46.61px',
              display: 'block',
              marginRight: 7,
              padding: 0,
              borderRadius: 10,
              boxShadow: 'rgba(0, 0, 0, 0.25) 1px 1px 2px',
            }}
            label={translate(button.label)}
          />
        ) : (
          <a style={{ cursor: 'pointer', height: 20, verticalAlign: 'middle' }} onClick={button.action}>
            {button.icon}
            <span style={styles.exhibitButtons.text}>{translate(button.label)}</span>
          </a>
        )}
      </div>
    )
  })

  return <div style={styles.exhibitButtons.container}>{renderButtons}</div>
}

const _SystemComponentBlock = ({ isMobile, component, showDialog, proposalData }) => {
  const {
    isFeatured,
    title,
    content,
    logoUrl,
    productImageUrl,
    other,
    videoUrl,
    productDetails,
    specSheetUrl,
  } = component
  const translate = useTranslate()
  const layout = isMobile ? 'mobile' : 'standard'
  const feature = isFeatured ? 'isFeatured' : 'noFeatured'
  const style = styles[layout][feature]
  const componentDetails = {
    user_id: proposalData?.org?.user?.id,
    role_id: null,
    component_code: component?.componentCode,
    component_manufacturer: component?.componentManufacturer,
    org_name: proposalData?.org?.name,
    project_id: proposalData?.org?.projects[0]?.id,
    is_showcase: component?.isFeatured,
  }
  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: '0px',
    threshold: 0.5,
    onChange: (inView) => {
      if (inView) {
        logAmplitudeEvent('proposal_component_viewed', componentDetails)
      }
    },
  })

  return (
    <div
      ref={ref}
      style={{ ...style.container, ...(logoUrl ? {} : { paddingBottom: '0' }) }}
      className="SystemComponentBlockHackDisplayFlex"
    >
      <div style={{ flex: '1 1 0', marginLeft: 10, marginTop: 7 }}>
        {title && (
          <div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              marginTop: 0,
              marginBottom: 3,
            }}
          >
            {translate(title)}
          </div>
        )}
        {content && (
          <div
            className={isFeatured ? 'component-details-featured' : 'component-details'}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {renderExhibitButtons(
          videoUrl,
          productDetails,
          specSheetUrl,
          showDialog,
          proposalData,
          translate,
          componentDetails
        )}
        {logoUrl && <img src={logoUrl} style={style.logo} />}
      </div>
      {productImageUrl && (
        <div style={style.productImageColumn}>
          {productImageUrl && <img src={productImageUrl} style={style.productImage} />}
          {other && other.length > 0 && <span className="small">{other}</span>}
        </div>
      )}
    </div>
  )
}

export const SystemComponentBlock = _SystemComponentBlock
