import { Typography } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import ConnectModal from './ConnectModal'
import DistributorOptions from './DistributorOptions'
import useGetDistributorConnectionStatusAndConfig from './hooks/useGetDistributorConnectionStatusAndConfig'
import PricingBox from './PricingBox'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 20,
    gap: 10,
    color: theme.greyDark2,
  },
  content: { maxWidth: 682, marginTop: 10 },
  font: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const ShopSection = ({ project }: { project: Record<string, any> }) => {
  const classes = useStyles()
  const history = useHistory()
  const orgId = useSelector(authSelectors.getOrgId)
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  const [open, setOpen] = useState<boolean>(false)
  const [selectedSupplier, setSelectedSupplier] = useState<HardwareSupplierFilterKeyType | undefined>(undefined)
  const {
    isChecking,
    atLeastOneDistributorEnabled,
    atLeastOneDistributorConnected,
    enabledAndConnectedDistributor,
    distributorConfigs,
  } = useGetDistributorConnectionStatusAndConfig(selectedSupplier)

  const onClose = () => {
    setOpen(false)
  }

  const onSelectSupplier = (supplier: HardwareSupplierFilterKeyType) => {
    setSelectedSupplier(supplier)
  }

  useEffect(() => {
    if (!isChecking) {
      setSelectedSupplier(enabledAndConnectedDistributor as HardwareSupplierFilterKeyType | undefined)
    }
  }, [isChecking, enabledAndConnectedDistributor])

  return (
    <div className={classes.container}>
      <section className={classes.leftSection}>
        <div className={classes.content}>
          <Typography variant="subtitle1" gutterBottom className={classes.font}>
            {atLeastOneDistributorConnected ? 'Find solar equipment for the best price' : 'Connect Hardware Supplier'}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {atLeastOneDistributorConnected
              ? orgCountry === 'AU' || orgCountry === 'PL'
                ? 'See BOM price from our partner hardware distributor'
                : 'Compare BOM prices from our partner hardware distributors'
              : 'The easiest way to get the hardware you need to complete your jobs'}
          </Typography>
          <DistributorOptions
            configs={distributorConfigs}
            onSelectSupplier={onSelectSupplier}
            onOpen={() => {
              if (orgCountry === 'AU' && !atLeastOneDistributorEnabled) {
                window.open('https://pages.opensolar.com/partners/solar-outlet#Getstarted')
                return
              }
              if ((orgCountry === 'GB' || orgCountry === 'PL') && !atLeastOneDistributorEnabled) {
                history.push(`/external_api_keys/${orgId}`)
                return
              }
              setOpen(true)
            }}
            atLeastOneDistributorConnected={atLeastOneDistributorConnected}
          />
        </div>
      </section>
      <section>
        <PricingBox
          project={project}
          selectedSupplier={selectedSupplier}
          atLeastOneDistributorConnected={atLeastOneDistributorConnected}
        />
        <ConnectModal open={open} distributorConfigs={distributorConfigs} onClose={onClose} />
      </section>
    </div>
  )
}

export default ShopSection
