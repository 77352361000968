import { Typography } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import ValidityChip from 'elements/chip/ValidityChip'
import inflection from 'inflection'
import { getCountrySuppliers, getSupplierEnumByFilterKey } from 'pages/ordering/configs'
import useHandleChangeSelectedDistributor from 'pages/ordering/subheader/useHandleChangeSelectedDistributor'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import HardwarePromo from 'projectSections/sections/manage/hardwarePromo/HardwarePromo'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrency, urlToId } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { COLOR_PALETTE } from '../styles'
import useGetHardwarePriceAndStock from './hooks/useGetHardwarePriceAndStock'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  logo: {
    width: 'auto',
    height: 32,
    objectFit: 'fill',
    padding: 5,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 10,
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #e7e7e7',
    borderRadius: 4,
    width: 'auto',
    height: 40,
  },
  priceFont: {
    fontWeight: theme.typography.fontWeightMedium,
    color: COLOR_PALETTE.blue3,
  },
}))

const PricingBox = ({
  atLeastOneDistributorConnected,
  project,
  selectedSupplier,
}: {
  atLeastOneDistributorConnected: boolean
  project: Record<string, any>
  selectedSupplier?: HardwareSupplierFilterKeyType
}) => {
  const classes = useStyles()
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  const countrySuppliers = getCountrySuppliers(orgCountry || '')
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = useMemo(() => currencySymbolForCountry(countryIso2), [countryIso2])
  const handleChangeSelectedDistributor = useHandleChangeSelectedDistributor()
  const { loadingHardware, totalQuantity, availableQuantity, discountedTotal } = useGetHardwarePriceAndStock(
    selectedSupplier
  )
  const supplierEnum = useMemo(() => {
    if (selectedSupplier) return getSupplierEnumByFilterKey(selectedSupplier)
  }, [selectedSupplier])
  const info = `${availableQuantity}/${totalQuantity} items available`
  const enableHdm = useFeatureFlag('hdm', 'on')
  const enableVtac = useFeatureFlag('vtac', 'on')

  const getValidity = () => {
    if (availableQuantity === 0) {
      return 'error'
    }
    if (availableQuantity === totalQuantity) {
      return 'valid'
    }
    if (availableQuantity < totalQuantity) {
      return 'warning'
    }
    return 'default'
  }

  const onChangeSelectedDistributor = () => {
    if (supplierEnum) {
      handleChangeSelectedDistributor(supplierEnum)
    }
  }

  const getSupplierName = () => {
    if (!selectedSupplier) return null

    if (selectedSupplier === 'hdm') {
      return 'HDM'
    } else if (selectedSupplier === 'vtac_uk' || selectedSupplier === 'vtac_pl') {
      return 'V-Tac'
    } else {
      return inflection.titleize(selectedSupplier)
    }
  }

  if (!atLeastOneDistributorConnected) {
    return (
      <div className={classes.logoContainer}>
        {countrySuppliers?.map((config, index) => {
          if (config.logoUrl.includes('hdm') && !enableHdm) {
            return null
          }
          if (config.logoUrl.includes('vtac') && !enableVtac) {
            return null
          }

          return (
            <div className={classes.logoWrapper}>
              <img key={index} src={config.logoUrl} alt="logo" className={classes.logo} />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {loadingHardware ? (
        <Skeleton width={190} height={20} />
      ) : (
        <div>
          <Typography variant="subtitle1" gutterBottom display="inline" className={classes.priceFont}>
            From {currencySymbol}
            {formatCurrency(discountedTotal)}{' '}
          </Typography>
          <Typography variant="subtitle1" gutterBottom display="inline">
            with {getSupplierName()}
          </Typography>
        </div>
      )}
      <Typography variant="subtitle1" gutterBottom>
        {loadingHardware ? (
          <Skeleton width={150} height={20} />
        ) : (
          <ValidityChip
            label={info}
            validity={getValidity()}
            title={
              getValidity() !== 'valid'
                ? 'Limited stock: Continue to ordering to resolve stock issues in the Order By Project page'
                : ''
            }
          />
        )}
      </Typography>
      {project !== null && (
        <HardwarePromo
          projectId={project.id}
          systems={project.systems}
          soldSystemId={urlToId(project.system_sold)}
          simplified={true}
          onChangeSelectedDistributor={onChangeSelectedDistributor}
          managePageSupplier={selectedSupplier}
        />
      )}
    </div>
  )
}

export default PricingBox
