import { FormControl, InputLabel } from '@material-ui/core'
import { SERVICE_OFFERING_OPTIONS } from 'constants/sectors'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import CheckboxMultiSelectInput from 'elements/input/CheckboxMultiSelectInput'
import UiContainer from 'elements/UiContainer'
import {
  AutocompleteInput,
  Edit,
  FormDataConsumer,
  ImageField,
  ImageInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import ImageFieldClearable from '../../elements/field/ImageFieldClearable'
import { ColorInput } from '../../elements/input/ColorInput'
import EditorComponent from '../../elements/wysiwyg/EditorComponentWrapper'
import { generateDefaultValueFunc, getRoleFromState, renderDraftEditor, renderRawHtmlEditor } from '../../util/misc'
import { validateOrgContentEdit } from '../../validations/Orgs'

export const FroalaEditorWithAboutContent = ({ record, ...rest }) => {
  return (
    <CustomField
      component={EditorComponent}
      fieldName="about_content"
      name="about_content"
      source="about_content"
      mentionContext="org"
      allowUploadImage={true}
      {...rest}
    />
  )
}

export const ShowHighLightColor = (props) => {
  const highlightColor = props.value
  return (
    <span
      style={{
        position: 'absolute',
        display: 'inline-block',
        top: 35,
        right: 10,
        width: 20,
        height: 20,
        borderRadius: '50%',
        zIndex: -1,
        background: highlightColor,
      }}
    />
  )
}

export const contentFields = [
  'name',
  'country',
  'address',
  'locality',
  'zip',
  'state',
  'sales_phone_number',
  'company_email',
  'company_website',
  'about_content',
  'logo_file_contents',
  'enable_exhibit',
  'color_highlight',
]

const inputStyle = {
  width: 256,
  maxWidth: 256,
  marginRight: 40,
}

const shortInputStyle = {
  width: 108,
  marginRight: 0,
}

const OrgContentEdit = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isConnectedToBrighte = useSelector(authSelectors.getBrighteConnected)
  const showBrighteId = window.location.href.includes('show_brighte_id=true')
  const uiKey = 'control.company.business_info.fields.'
  return (
    <UiContainer uiKey={uiKey}>
      <Edit {...props}>
        <SimpleForm
          redirect={'/control'}
          validate={(values) => validateOrgContentEdit(translate, values)}
          defaultValue={generateDefaultValueFunc(contentFields)}
          disabled={!allowEdit}
        >
          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
            <TextInput label="resources.orgs.fields.name" source="name" style={inputStyle} />
            <TextInput
              label="resources.orgs.fields.sales_phone_number"
              source="sales_phone_number"
              type="tel"
              style={inputStyle}
            />
            <TextInput
              label="resources.orgs.fields.company_email"
              source="company_email"
              type="email"
              style={inputStyle}
            />

            <TextInput label="resources.orgs.fields.company_website" source="company_website" style={inputStyle} />
            <TextInput label="resources.orgs.fields.address" source="address" style={inputStyle} />
            <TextInput label="resources.orgs.fields.locality" source="locality" style={inputStyle} />

            <div style={{ width: 256, marginRight: 40 }}>
              <CustomField
                component={TextInput}
                label="resources.orgs.fields.state"
                name="state"
                source="state"
                style={{ ...shortInputStyle, marginRight: 40 }}
                {...props}
              />
              <CustomField
                component={TextInput}
                label="resources.orgs.fields.zip"
                name="zip"
                source="zip"
                style={shortInputStyle}
                {...props}
              />
            </div>
            <div style={{ width: 256, marginRight: 40, marginTop: '-7px' }}>
              <ReferenceInput
                label="resources.orgs.fields.country"
                name="country"
                source="country"
                reference="countries"
                optionValueField="url"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}
                allowEmpty={isAdmin}
                {...props}
              >
                <AutocompleteInput
                  optionText="name"
                  optionValue="url"
                  allowDuplicates={true}
                  options={{
                    style: inputStyle,
                    containerStyle: inputStyle,
                    placeholder: 'Enter a country',
                  }}
                />
              </ReferenceInput>
            </div>
            <UiContainer uiKey={uiKey + 'color_highlight'} style={{ position: 'relative', ...inputStyle, zIndex: 91 }}>
              <CustomField
                component={isAdmin ? ColorInput : TextInput}
                label={'resources.orgs.fields.color_highlight'}
                name="color_highlight"
                source="color_highlight"
                {...props}
              />
              <FormDataConsumer>
                {({ formData }) => <ShowHighLightColor value={formData.color_highlight} />}
              </FormDataConsumer>
            </UiContainer>
            <div style={{ minWidth: 256, marginRight: 40, marginTop: -6 }}>
              <FormControl style={{ width: '100%' }} margin="dense">
                <InputLabel id="service-offering">Service Offering</InputLabel>
                <CheckboxMultiSelectInput
                  id="service-offering"
                  source={'service_offering'}
                  choices={SERVICE_OFFERING_OPTIONS}
                  color={'secondary'}
                  fullWidth
                />
              </FormControl>
            </div>
            {isStaff && isConnectedToBrighte && showBrighteId && (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <h4>Brighte Vendor Id</h4>
                <p style={{ marginBotton: '0px' }}>
                  <strong>Warning:</strong> The Brighte vendor id is a key piece of the Brighte intgration. Please do
                  not change this unless you're certain you're changing it to the correct value.
                </p>
                <TextInput
                  label="Brighte Vendor Id"
                  source="brighte_vendor_id"
                  type="tel"
                  style={inputStyle}
                  disabled={!isStaff}
                />
              </div>
            )}
          </div>
          <UiContainer uiKey={uiKey + 'about_content'}>
            {({ ...rest }) => (
              <>
                <h1>{translate('About Us')}</h1>

                <FormDataConsumer {...rest}>
                  {({ formData, ...rest }) =>
                    renderDraftEditor(formData.about_content) && <FroalaEditorWithAboutContent {...rest} />
                  }
                </FormDataConsumer>

                <FormDataConsumer {...rest}>
                  {({ formData, ...rest }) =>
                    renderRawHtmlEditor(formData.about_content) && (
                      <TextInput
                        label="resources.orgs.fields.about_content"
                        multiline
                        source="about_content"
                        {...rest}
                      />
                    )
                  }
                </FormDataConsumer>
              </>
            )}
          </UiContainer>
          <div style={{ clear: 'both', height: 20 }} />
          <h1>{translate('Logo')}</h1>
          <ImageFieldClearable
            label="resources.orgs.fields.logo_file_contents"
            source="logo_file_contents"
            maxWidth={200}
          />
          {isAdmin && (
            <ImageInput
              source="logo_file_contents"
              label="resources.orgs.fields.logo_file_contents"
              accept="image/*"
              placeholder={<p>{translate('Click to upload or drag & drop an image file here')}</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          )}
        </SimpleForm>
      </Edit>
    </UiContainer>
  )
}

export default OrgContentEdit
