import { AppBar, useMediaQuery } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { studioSelectors } from 'ducks/studioMode'
import { UiSwitch } from 'elements/UiSwitch'
import { BaseThemeType, ComponentVersionsInherit, styled } from 'opensolar-ui'
import SupplierWidget from 'pages/ordering/subheader/SupplierWidget'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Actions } from '../header/actions'
import UndoRedoButtons from '../header/actions/UndoRedoButtons'
import MobileDesignPageActions from './MobileDesignPageActions'
import MobileNavSelector from './MobileNavSelector'
import NavLinks from './NavLinks'
import Summary from './Summary'

const NavBar = styled(AppBar)(
  {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgb(255,255,255)',
    color: '#000000',
    height: 56,
    padding: '0 20px',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%)',
    borderTop: '1px solid #0000001a',
    position: 'sticky',
  },
  { name: 'ProjectNav', slot: 'navBar' }
)

const StyledDiv = styled('div')(
  {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  { name: 'ProjectNav', slot: 'container' }
)
const SummaryWrapper = styled('div')(
  {
    marginRight: 15,
  },
  { name: 'ProjectNav', slot: 'summaryWrapper' }
)
const DesktopLayout = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    width: '100%',
    alignItems: 'center',
  },
  { name: 'ProjectNav', slot: 'desktopLayout' }
)

const ActionsWrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  { name: 'ProjectNav', slot: 'actionsWrapper' }
)
const SupplierWidgetWrapper = styled('div')(
  {
    border: '1px solid #0000001a',
    borderRadius: '4px',
    width: 120,
    margin: '0px 15px',
  },
  { name: 'ProjectNav', slot: 'supplierWidgetWrapper' }
)
interface PropTypes {
  submitting: boolean
}

const ProjectNav: React.FC<PropTypes> = ({ submitting }) => {
  const isDesktop = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.up('xl'))
  const isTablet = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.between('sm', 'lg'))
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))

  const isDesignPage = useSelector((state: any) => state.project?.section === 'design')
  const isProposalPage = useSelector((state: any) => state.project?.section === 'proposal')
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const isInStudio = useSelector(studioSelectors.isInStudio)
  const form = useForm()
  useFormState()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const disableActions = dirtyFields?.length === 0
  return (
    <UiSwitch uiKey="forms.project.header">
      <ComponentVersionsInherit versions={{ button: 3, icon_button: 2, chip: 2 }}>
        <NavBar>
          {isDesktop && (
            <DesktopLayout>
              <Summary mobile={isMobile} />
              <NavLinks />
              <ActionsWrapper>
                {isDesignPage && enabledDistributors.length > 1 && (
                  <SupplierWidgetWrapper>
                    <SupplierWidget hideSupplierLabel mode="logo" />
                  </SupplierWidgetWrapper>
                )}
                <Actions submitting={submitting} />
              </ActionsWrapper>
            </DesktopLayout>
          )}
          {isTablet && (
            <StyledDiv>
              <SummaryWrapper>
                <Summary mobile={isMobile} />
              </SummaryWrapper>
              <MobileNavSelector />
              {isDesignPage && enabledDistributors.length > 1 && (
                <SupplierWidgetWrapper>
                  <SupplierWidget hideSupplierLabel mode="logo" />
                </SupplierWidgetWrapper>
              )}
              <Actions submitting={submitting} />
            </StyledDiv>
          )}
          {isMobile && (
            <StyledDiv>
              <Summary mobile={isMobile} />
              {!isProposalPage && <MobileDesignPageActions submitting={submitting} />}{' '}
            </StyledDiv>
          )}
        </NavBar>
        {isMobile && (
          <NavBar>
            <StyledDiv>
              <MobileNavSelector />
              {isDesignPage && enabledDistributors.length > 1 && (
                <SupplierWidgetWrapper>
                  <SupplierWidget hideSupplierLabel mode="logo" />
                </SupplierWidgetWrapper>
              )}
              {isInStudio && <UndoRedoButtons layout={3} disableActions={disableActions} />}
            </StyledDiv>
          </NavBar>
        )}
      </ComponentVersionsInherit>
    </UiSwitch>
  )
}

export default ProjectNav
