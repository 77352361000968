import { Divider, FormControlLabel, Link, Radio, RadioGroup, TextField, Theme, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { PERFORMANCE_CALCULATORS_MCS } from 'constants/calculators'
import { ToolsBatteries } from 'Designer/Toolbars'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import AssociatedComponentsAlert from 'elements/associatedComponents/AssociatedComponentsAlert'
import { DraggableList } from 'elements/dnd/DraggableList'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import { BATTERY_COMPATIBILITY_FILTER_KEY } from 'elements/hardwareFilter/batteryCompatibility/BatteryCompatibilityFilterNodeFactory'
import { getCompatibleCodesForSystem } from 'elements/hardwareSelector/SearchToolbar'
import BatterySalesAssistantDialog from 'elements/hardwareSelectorV2/batteryWizard/BatterySalesAssistantDialog'
import { withHardwareSelectorV2, WithHardwareSelectorV2Props } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { Box, Typography } from 'opensolar-ui'
import { addDefaultComponentOrChooseFromDialog } from 'projectSections/sections/design/util/addDefaultComponentOrChooseFromDialog'
import { useTranslate } from 'ra-core'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { StudioSystemType } from 'types/global'
import { RootState } from 'types/state'
import { trimDecimalPlaces } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import useTranslateParse from 'util/useTranslateParse'
import {
  handleObjectChangedAsCommand,
  inputValueToFloatOrEmptyStringWhenNull,
  styles,
  valueOrEmptyStringIfNull,
} from '../../PanelSystem'
import PanelGenericWarningBox from '../../warning/PanelGenericWarningBox'
import ComponentCollection from '../ComponentCollection'
import BatteryBlock from './BatteryBlock'
import { OffsetableField } from './OffsetableField'

export const DEFAULT_BATTERY_CONTROL_SCHEME = 'solar_charged'

const batteryControlSchemeDefaultOptions = [
  { id: 'solar_charged', title: 'Self-consumption' },
  {
    id: 'minimize_grid_costs_tou_no_grid_charging',
    title: 'Minimize Grid Import Cost',
  },
  {
    id: 'maximize_savings',
    title: 'Maximize Savings',
  },
  {
    id: 'peak_demand_shaving',
    title: 'Peak Demand Shaving',
  },
]

interface Props extends WithHardwareSelectorV2Props {
  countryIso2: string
  allowEdit: boolean
  system: StudioSystemType

  //TODO: Should remove the need for this
  panelSystem: any
}

const BatteryPanelComponent: FC<Props> = ({
  allowEdit,
  countryIso2,
  enableHardwareDialogV2,
  handleOpenHardwareSelector,
  panelSystem,
  system,
}) => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const dispatch = useDispatch()
  const isCalculatorMCS = system.calculator === PERFORMANCE_CALCULATORS_MCS
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const projectId = useSelector((state: RootState) => state.projectId)

  const enableBatterySalesAssistant = useFeatureFlag('enable_battery_wizard', 'on')
  const [lenderBatteryPriceOverride, setLenderBatteryPriceOverride] = useState(system.lender_battery_price_override)
  const [isBatterySalesAssistantOpen, setIsBatterySalesAssistantOpen] = useState(false)

  function handleIsBatteryDcConnected(value, uuid) {
    var system = window.editor.objectByUuid(uuid)
    window.editor.execute(new window.SetValueCommand(system, 'is_battery_dc_connected', value))
  }

  function handleBatteryControlScheme(value, uuid) {
    var system = window.editor.objectByUuid(uuid)
    window.editor.execute(new window.SetValueCommand(system, 'battery_control_scheme', value))
  }

  const getBattSchemes = isCalculatorMCS ? [] : window.AccountHelper.loadedData?.batterySchemes

  const batterySchemesMsg = translateParse('Learn more about OpenSolar battery modeling <a>here<a>', {
    a: (label) => (
      <Link
        target="_blank"
        rel="noreferrer"
        color="inherit"
        href="https://support.opensolar.com/hc/en-us/articles/4650412864527-How-OpenSolar-Models-Battery-Energy-Storage-"
      >
        {label}
      </Link>
    ),
  })

  const loadOffsetableMsg = translateParse(
    '<h1>Load offsettable settings<h1><p>These fields are used to set the portion of the load/usage that the battery can offset.<p><a>Read more here<a>',
    {
      h1: (label) => <h4>{label}</h4>,
      p: (label) => <p>{label}</p>,
      a: (label) => (
        <Link
          target="_blank"
          rel="noreferrer"
          color="inherit"
          href="https://support.opensolar.com/hc/en-us/articles/4650412864527-How-OpenSolar-Models-Battery-Energy-Storage"
        >
          {label}
        </Link>
      ),
    }
  )

  const onManualAddComponent = () => {
    if (enableHardwareDialogV2 && !isMobile) {
      const compatibleBatteryCodes = getCompatibleCodesForSystem('battery')
      const defaultFilterValues = {
        ...(compatibleBatteryCodes.length
          ? { [BATTERY_COMPATIBILITY_FILTER_KEY]: compatibleBatteryCodes.join(',') }
          : {}),
      }

      handleOpenHardwareSelector({ componentTypes: ['battery'], title: 'Select Battery', defaultFilterValues })
    } else {
      dispatch(
        setSelectComponentDialog(
          true,
          window.WorkspaceHelper.project.org_id,
          window.WorkspaceHelper.project.id,
          null,
          'battery',
          window.AccountHelper.getComponentBatterySpecsAvailable(),
          null
        )
      )
    }
  }

  const getDefaultBatteryControlSchemes = () => {
    if (countryIso2 === 'NZ')
      return batteryControlSchemeDefaultOptions.concat([{ id: 'nz_optimized', title: 'NZ Optimized' }])
    else if (isCalculatorMCS) return batteryControlSchemeDefaultOptions.filter(({ id }) => id === 'solar_charged')
    else return batteryControlSchemeDefaultOptions
  }

  return (
    //@ts-ignore
    <div style={styles.expansionPanelDetailsContainer}>
      <PanelGenericWarningBox category="battery" />
      <AssociatedComponentsAlert associationType="require" filter={{ componentTypes: ['battery'] }} />
      <AssociatedComponentsAlert associationType="recommend" filter={{ componentTypes: ['battery'] }} />

      <ToolsBatteries
        system={system}
        disabled={!allowEdit}
        plusOnClick={() => {
          logAmplitudeEvent('battery_tab_add_battery_clicked', { bda_true: enableBatterySalesAssistant })
          if (enableBatterySalesAssistant && !isMobile && projectId) setIsBatterySalesAssistantOpen(true)
          else addDefaultComponentOrChooseFromDialog('battery', system.uuid, onManualAddComponent)
        }}
      />
      <DraggableList
        handleReorder={(oldOrder, newOrder) => {
          window.editor.execute(new window.ReorderChildrenCommand(system, oldOrder, newOrder))
        }}
        list={system.batteries()}
        render={(item, { dragHandle }) => <BatteryBlock item={item} disabled={!allowEdit} dragHandle={dragHandle} />}
        applyDragStyles={true}
      />

      {allowEdit && system.batteries().length > 0 && (
        <>
          {window.editor.selectedSystem.consumption && (
            <Box display="flex" flexDirection="column" gridRowGap={8} padding={1}>
              <Box display="flex" justifyContent="space-between">
                <Typography textVariant="caption1">{translate('Grid Independence')}</Typography>
                <Typography textVariant="caption2">
                  {`${trimDecimalPlaces(
                    window.editor.selectedSystem.consumption.self_consumption_percentage_no_battery,
                    0
                  )}%`}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography textVariant="caption1">{translate('Self-consumption')}</Typography>
                <Typography textVariant="caption2">
                  {`${trimDecimalPlaces(
                    window.editor.selectedSystem.consumption.grid_independence_percentage_no_battery,
                    0
                  )}%`}
                </Typography>
              </Box>
            </Box>
          )}
          <Divider style={{ height: 1, marginTop: 20 }} />

          <RadioGroup
            name="is_battery_dc_connected"
            value={Boolean(system.is_battery_dc_connected)}
            style={{ flexDirection: 'row', marginTop: 10 }}
            onChange={(e) => {
              // Workaround Material-UI using string values instead of the real value
              handleIsBatteryDcConnected(e.target.value === 'true', system.uuid)
            }}
          >
            <FormControlLabel
              style={{ fontSize: 12 }}
              control={<Radio value={false} />}
              label={translate('AC-connected')}
            />
            <FormControlLabel
              style={{ fontSize: 12 }}
              control={<Radio value={true} />}
              label={translate('DC-connected')}
            />
          </RadioGroup>
        </>
      )}

      {allowEdit && system.batteries().length > 0 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SharedEntitySelectInput
            choices={getBattSchemes}
            optionText={'name'}
            variant="outlined"
            style={{ flex: '1 1', width: '100%', marginRight: 0 }}
            label={translate('Battery Control Scheme')}
            value={system.battery_control_scheme || DEFAULT_BATTERY_CONTROL_SCHEME}
            onChange={(event) => handleBatteryControlScheme(event.target.value, system.uuid)}
            defaultOptions={getDefaultBatteryControlSchemes()}
          />
          <InfoTooltip
            title={
              isCalculatorMCS
                ? 'To use other battery control schemes, you can switch to other calculators available in OpenSolar, such as the SAM calculator and PVWatts calculator.'
                : batterySchemesMsg
            }
            interactive={true}
          />
        </div>
      )}
      {allowEdit && system.batteries().length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <OffsetableField
            label="Load Offsettable"
            system={system}
            panelSystem={panelSystem}
            systemField="load_offsettable_by_battery_fraction"
            unit="%"
            multiplier={100}
            errorText="Supply as a number or leave blank"
          />
          <OffsetableField
            label="Load Offsettable Cap"
            system={system}
            panelSystem={panelSystem}
            systemField="load_offsettable_by_battery_cap"
            unit={translate('kW')}
            errorText="Supply export limit as a number or leave blank"
          />
          <InfoTooltip interactive={true} title={loadOffsetableMsg} />
        </div>
      )}
      {allowEdit && system.batteries().length > 0 && !!system.lender_battery_price_override && (
        <>
          <TextField
            type="number"
            label="Sungage Battery Price Override"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '100%' }}
            fullWidth
            name="Sungage Battery Price Override"
            value={valueOrEmptyStringIfNull(lenderBatteryPriceOverride)}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'lender_battery_price_override', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              startAdornment: '$',
            }}
            onChange={(event) => {
              const value = event.target.value
              setLenderBatteryPriceOverride(parseFloat(value))

              var valueAsFloatOrNull = inputValueToFloatOrEmptyStringWhenNull(value)

              handleObjectChangedAsCommand(system.uuid, 'lender_battery_price_override', valueAsFloatOrNull)
            }}
          />
          <span className="small">
            Equivalent to the battery price you would enter in Sungage's portal. Used for validation only and does not
            impact system price/cost
          </span>
        </>
      )}
      {(!system.is_battery_dc_connected ||
        !!system.others().filter((other) => other.other_component_type === 'battery_inverter').length) && (
        <ComponentCollection
          style={{ marginTop: 20 }}
          title={translate('Battery Inverters')}
          dialogSelectorTitle={translate('Select Battery Inverters')}
          systemUuid={system.uuid}
          others={system.others()}
          filterParams={{ other_component_type: 'battery_inverter' }}
          disabled={!allowEdit}
        />
      )}
      <ComponentCollection
        style={{ marginTop: 20 }}
        title={translate('Battery Accessories')}
        dialogSelectorTitle={translate('Select Battery Accessories')}
        systemUuid={system.uuid}
        others={system.others()}
        filterParams={{ other_component_type: 'battery_accessory' }}
        disabled={!allowEdit}
      />
      {projectId !== undefined && (
        <BatterySalesAssistantDialog
          projectId={projectId}
          systemUuid={system.uuid}
          isOpen={isBatterySalesAssistantOpen}
          onClose={() => setIsBatterySalesAssistantOpen(false)}
        />
      )}
    </div>
  )
}

export const BatteryPanel = withHardwareSelectorV2(BatteryPanelComponent)
