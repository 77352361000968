import { Divider, Typography } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { clearLineItems } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { Grid } from 'opensolar-ui'
import { memo, useCallback, useContext, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import CheckoutPageLayout from '../../PageLayout'
import CheckoutTitle from '../CheckoutTitle'
import ReviewItems from '../ReviewItems'
import { useCheckoutStyles } from '../styles'
import Alert from './Alert'
import SegenPlaceOrderButton from './ButtonCheckoutSegen'
import PlaceOrderSideAction from './PlaceOrderAction'
import { CheckoutFormContext, CheckoutPresenterContext, QuoteContext } from './SegenCheckoutProvider'
import SegenDisclaimer from './SegenDisclaimer'
import ShippingAddress from './ShippingAddress'
import ShippingSchedule from './ShippingSchedule'
const useContentActionStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    flexWrap: 'wrap',
  },
  infoWrapper: {},
  button: {
    flexGrow: 1,
    padding: 10,
    maxWidth: 300,
  },
  wrapper: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  cost: {
    fontSize: '18px',
    color: '#018030',
  },
}))

const MainContentAction = memo(({ handlePlaceOrder }: { handlePlaceOrder(): void }) => {
  const { loading, quote } = useContext(QuoteContext)
  const classes = useContentActionStyles()
  const currencySymbol = useSelector((state: RootState) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })
  const totalAmount = quote?.totalsResponse.grossTotal.amount

  return (
    <div className={classes.container}>
      <div className={classes.button}>
        <SegenPlaceOrderButton onClick={handlePlaceOrder} trackSource="checkout_content" />
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.wrapper}>
          <span className={classes.label}>Order total:</span>
          {loading ? (
            <Skeleton />
          ) : (
            <span className={classes.cost}>{`${currencySymbol} ${formatCurrency(totalAmount)}`}</span>
          )}
        </div>
        <SegenDisclaimer />
      </div>
    </div>
  )
})

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  section: {
    padding: 20,
  },
  divider: {
    height: 2,
    margin: '20px 0',
  },
  item: {
    padding: '20px 30px 0px 30px',
  },
}))

const MainContent = memo(({ handlePlaceOrder }: { handlePlaceOrder(): void }) => {
  const classes = useStyles()
  const checkoutClassess = useCheckoutStyles()
  const { quote } = useContext(QuoteContext)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Alert
          title={`Congratulations! 30 days price lock is enabled for this quote and your Segen reference ID is ${quote?.reference}.`}
          severity="success"
        />
      </Grid>
      <Grid item xs={12} style={{ margin: '10px 0px -10px 0px' }}>
        <Alert
          title="What’s next after scheduling my order?"
          subtitle="Once sent, your order will be transferred to the Segen Portal for payment processing and final shipping confirmation. You will then receive a Segen order reference ID for tracking purposes."
          severity="info"
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" className={`${checkoutClassess.font18} ${checkoutClassess.fontWeightMedium}`}>
          <span style={{ marginRight: '20px' }}>{1}</span>
          <span>Shipping address</span>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.item}>
        <ShippingAddress />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" className={`${checkoutClassess.font18} ${checkoutClassess.fontWeightMedium}`}>
          <span style={{ marginRight: '20px' }}>{2}</span>
          <span>Select shipping</span>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.item}>
        <ShippingSchedule />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <ReviewItems itemNumber={3} />
      </Grid>
      <Grid item xs={12}>
        <MainContentAction handlePlaceOrder={handlePlaceOrder} />
      </Grid>
    </Grid>
  )
})

const deliveryMethodPreferenceToId = (deliveryMethod: string) => {
  var deliveryMethods = [
    'Cheapest',
    'Next Day',
    'Before Midday',
    'Before 10:30am',
    'Before 9:00am',
    'Timed',
    'Dedicated Delivery',
    'Dedicated Delivery Timed',
  ]
  return deliveryMethods.indexOf(deliveryMethod)
}

const PreOrderPage = () => {
  const { quote } = useContext(QuoteContext)
  const dispatch = useDispatch()
  useEffect(() => {
    logAmplitudeEvent('hardware_segen_checkout', {
      action: 'viewed',
      context: 'segen_checkout_page',
    })
  }, [])
  const history = useHistory()
  const segenCheckoutPresenter = useContext(CheckoutPresenterContext)
  const {
    address,
    hasLiftingEquipment,
    deliveryMethodPreference,
    segenOptions: { deliveryMethodOptions },
  } = useContext(CheckoutFormContext)

  const handlePlaceOrder = useCallback(async () => {
    if (quote?.id) {
      const order = await segenCheckoutPresenter?.placeOrder(
        quote?.id,
        {
          address: address,
          deliveryMethodPreference: deliveryMethodPreference,
          hasLiftingEquipment: hasLiftingEquipment,
        },
        quote.stockLocationName
      )
      if (order != null && !order?.error && quote?.portalUrl) {
        dispatch(clearLineItems())
        window.location.href = quote?.portalUrl
      }
    } else {
      console.error('quoteId not available, unable to submit')
    }
  }, [quote?.id, address, deliveryMethodPreference, hasLiftingEquipment])

  return (
    <CheckoutPageLayout
      title={<CheckoutTitle />}
      mainContent={<MainContent handlePlaceOrder={handlePlaceOrder} />}
      sideAction={<PlaceOrderSideAction handlePlaceOrder={handlePlaceOrder} />}
    />
  )
}

export default memo(PreOrderPage)
