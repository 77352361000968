import PowerIcon from '@material-ui/icons/Power'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { COLOR_PALETTE } from '../styles'
import DistributorChip, { ExtendedHardwareSupplierConfig } from './DistributorChip'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 10,
  },
  setupButton: {
    background: `${COLOR_PALETTE.blue3} !important`,
    color: `${theme.white} !important`,
  },
}))

const DistributorOptions = ({
  configs,
  onSelectSupplier,
  onOpen,
  atLeastOneDistributorConnected,
}: {
  configs: ExtendedHardwareSupplierConfig[]
  onSelectSupplier: (supplier: HardwareSupplierFilterKeyType) => void
  onOpen: () => void
  atLeastOneDistributorConnected: boolean
}) => {
  const classes = useStyles()
  const history = useHistory()
  const orgId = useSelector(authSelectors.getOrgId)

  if (!atLeastOneDistributorConnected) {
    return (
      <Button variant="contained" className={classes.setupButton} onClick={onOpen}>
        Setup
      </Button>
    )
  }

  return (
    <div className={classes.container}>
      {configs
        .filter((config) => config.isVisible)
        .map((config, index) => (
          <DistributorChip
            key={index}
            isWrapped={false}
            config={config}
            button={
              <Button
                size="small"
                variant="contained"
                color="info"
                onClick={() => {
                  if (!config.isEnabled) {
                    history.push(`/external_api_keys/${orgId}`)
                    return
                  }
                  onOpen()
                }}
              >
                {config.isEnabled ? 'Connect' : 'Enable'}
              </Button>
            }
            icon={<PowerIcon htmlColor={COLOR_PALETTE.green1} />}
            action={onSelectSupplier}
            numberOfAvailableDistributor={configs.length}
          />
        ))}
    </div>
  )
}

export default DistributorOptions
