import { Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import { Add, PeopleAlt } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import Tooltip from 'elements/tooltip/Tooltip'
import UserIcon from 'elements/UserIcon'
import { BaseThemeType, ToggleButton, ToggleButtonGroup } from 'opensolar-ui'
import TeamBadgeTooltipContent from 'projectSections/sections/manage3/common/TeamBadgeTooltipContent'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { RoleType } from 'types/roles'
import { Theme } from 'types/themes'
import HoverEditWrapper from '../../../common/HoverEditWrapper'
import { COLOR_PALETTE, getProjectSectionStyles } from '../../../styles'
import ExternalTeams from './ExternalTeams'
import InternalTeams, { PROJECT_ROLES } from './InternalTeams'

const useStyles = makeStyles(
  (theme: Theme) => ({
    contentWrapper: {
      minHeight: 30,
      display: 'flex',
      alignItems: 'center',
      color: COLOR_PALETTE.darkGrey,
      marginTop: 8,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    teamIcons: {
      marginRight: 10,
      position: 'relative',
    },
    toggleWrapper: {
      marginBottom: 20,
      marginTop: 10,
    },
    addBtn: {
      margin: '0 !important',
      marginTop: '8px !important',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    altIcon: {
      position: 'absolute',
      bottom: -3,
      right: -5,
      height: 15,
      color: 'green',
    },
    shareIcons: {
      position: 'relative',
    },
  }),
  { name: 'ProjectTeams' }
)

const roleFields = PROJECT_ROLES.map((x) => x.id)
const ProjectTeams = () => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const shared_with = useMemo(() => project.shared_with, [project.shared_with])
  const internalTeam = useMemo(() => {
    let teamMembers: RoleType[] = []
    roleFields.forEach((field) => {
      const roleData = project[field + '_data']
      if (roleData) {
        teamMembers.push({ ...roleData, projectRoleId: field })
      }
    })
    return teamMembers
  }, [
    project.assigned_role_data,
    project.assigned_salesperson_role_data,
    project.assigned_designer_role_data,
    project.assigned_site_inspector_role_data,
    project.assigned_installer_role_data,
  ])

  const internalTeamBrief = useMemo(() => internalTeam?.slice(0, 3), [internalTeam])

  useEffect(() => {
    roleFields.forEach((field) => {
      form.registerField(field, () => {}, {})
    })
  }, [])
  useEffect(() => {
    if (project.internalTeam !== undefined) {
      roleFields.forEach((field) => {
        const member = project.internalTeam?.find((x) => x.projectRoleId === field)
        form.change(field, member?.url || null)
        form.change(field + '_data', member)
      })
    }
  }, [project.internalTeam])

  const [activeTeamMember, setActiveTeamMember] = useState<RoleType>()
  const teamsClasses = getProjectSectionStyles()
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))

  return (
    <HoverEditWrapper
      modalProps={{
        headerText: translate('Team Members'),
        content: <TeamsDialogContent project={project} />,
        initialValues: {
          ...project,
          internalTeam,
        },
      }}
      className={isMobile ? teamsClasses.mobileViewDiv : `${teamsClasses.detail} ${teamsClasses.teamsCustomWidth}`}
      titleElement={<p>{translate('Team')}</p>}
      emptyToggle={
        !!!shared_with?.length && !!!internalTeamBrief.length
          ? {
              label: <>{translate('Add Team')}</>,
              startIcon: <Add />,
              className: classes.addBtn,
            }
          : undefined
      }
    >
      <Tooltip
        title={
          <TeamBadgeTooltipContent
            activeTeamMember={activeTeamMember}
            sharedwith={shared_with ? shared_with[0] : null}
          />
        }
        placement="bottom-start"
      >
        <div className={classes.contentWrapper}>
          <Grid container direction="row" wrap="nowrap" className={classes.teamIcons}>
            {!!internalTeamBrief.length &&
              internalTeamBrief.map((x, i) => (
                <Grid key={'team-' + i} item onMouseEnter={() => setActiveTeamMember(x)}>
                  <UserIcon size={28} user={x} />
                </Grid>
              ))}
            {shared_with && !!shared_with.length && (
              <Grid item onMouseEnter={() => setActiveTeamMember(undefined)}>
                <div className={classes.shareIcons}>
                  <UserIcon org={shared_with[0]} />
                  <PeopleAlt className={classes.altIcon} />
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </Tooltip>
    </HoverEditWrapper>
  )
}

const TeamsDialogContent = ({ project }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [editingInternal, setEditingInternal] = useState(true)
  const connectedOrgs = useSelector(orgSelectors.getConnectedOrgs)
  const teamsEnabled = useMemo(() => !!connectedOrgs.filter((x) => x.is_active)?.length, [connectedOrgs])

  return (
    <>
      {teamsEnabled && (
        <ToggleButtonGroup
          className={classes.toggleWrapper}
          fullWidth={true}
          exclusive
          value={editingInternal}
          onChange={(e, value) => {
            setEditingInternal(value)
          }}
        >
          <ToggleButton value={true}>{translate('Internal Team')}</ToggleButton>
          <ToggleButton value={false}>{translate('External Organisation')}</ToggleButton>
        </ToggleButtonGroup>
      )}
      {editingInternal ? <InternalTeams /> : <ExternalTeams project={project} />}
    </>
  )
}

export default ProjectTeams
